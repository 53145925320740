


import './factory.css';
import web3 from 'web3';
import * as contractModule from "./factoryvariables-contracts.js";
import * as networkModule from "./factoryvariables-networks.js";
import * as tokenModule from "./factoryvariables-tokens.js";
import * as feesModule from "./factoryvariables-fees.js";


//________________________________________________________________________________________________________________________________
//SWITCH BASED ON SELECTED token

export function CheckTokenNetwork(value) {
    console.log("Checking network for token:", value);
    switch (value) {
        case "SGB":
        case "WSGB":
        case "PERK[S]":
            networkModule.UseSGBnetwork();
            break;
        case "FLR":
        case "WFLR":
        case "PERK[F]":
            networkModule.UseFLRnetwork();
            break;
        case "CFLR":
        case "PERK[C]":
            networkModule.UseCOSTONnetwork();
            break;
        default:
            console.warn("Unknown token value:", value);
            break;
    }
}

//________________________________________________________________________________________________________________________________


export const tokenOptions = [
    { value: "FIAT", label: "FIAT", address: "0x1000000000000000000000000000000000000000", createContract: null },
    { value: "CFLR", label: "CFLR", address: "0x0000000000000000000000000000000000000000", createContract: null },
    { value: "SGB", label: "SGB", address: "0x0000000000000000000000000000000000000000", createContract: null },
    { value: "WSGB", label: "WSGB", address: contractModule.TokenWSGB, createContract: (signer) => contractModule.createWSGBToken(signer) },
    { value: "FLR", label: "FLR", address: "0x0000000000000000000000000000000000000000", createContract: null },
    { value: "WFLR", label: "WFLR", address: contractModule.TokenWFLR, createContract: (signer) => contractModule.createWFLRToken(signer) },
    { value: "PERK[C]", label: "PERK[C]", address: contractModule.PERKTokenCoston, createContract: (signer) => contractModule.createPERKTokenCoston(signer) },
    { value: "PERK[S]", label: "PERK[S]", address: contractModule.PERKTokenSGB, createContract: (signer) => contractModule.createPERKTokenSGB(signer) },
    { value: "PERK[F]", label: "PERK[F]", address: contractModule.PERKTokenFLR, createContract: (signer) => contractModule.createPERKTokenFLR(signer) },
];