


import './factory.css';
import web3 from 'web3';

//________________________________________________________________________________________
//NETWORK VARIABLES

//CHECK NETWORK CONNECTION
export async function UseCOSTONnetwork(){
        //reset the local variable for network switches (so they only show briefly when switched)
    localStorage.setItem('chainNameCard', '');
    localStorage.setItem('explorerLink', 'https://coston-explorer.flare.network/tx/');

        //switch to correct network, if needed
        if (window.ethereum.networkVersion !== '16') {
            switchtoCoston();
        }
    }
export async function UseSGBnetwork() {
    //reset the local variable for network switches (so they only show briefly when switched)
    localStorage.setItem('chainNameCard', '');
    localStorage.setItem('explorerLink', 'https://songbird-explorer.flare.network/tx/');

        //switch to correct network, if needed
        if (window.ethereum.networkVersion !== '19') {
            switchtoSGB();
        }
    }
export async function UseFLRnetwork() {
    //reset the local variable for network switches (so they only show briefly when switched)
    localStorage.setItem('chainNameCard', '');
    localStorage.setItem('explorerLink', 'https://flare-explorer.flare.network/tx/');

        //switch to correct network, if needed
        if (window.ethereum.networkVersion !== '14') {
            switchtoFLR();
        }
    }
//________________________________________________________________________________________________________________________________
//CHANGE NETWORKS
export async function switchtoCoston() {
        const chainId = 16 // 16 is Coston [SGB]
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.toHex(chainId) }]
            });

            //store the new chain name in local storage to show user
            localStorage.setItem('chainNameCard', 'you were switched to Coston');

        } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: 'Coston Test Network',
                            chainId: web3.utils.toHex(chainId),
                            nativeCurrency: { name: 'Coston Test Network', decimals: 18, symbol: 'CFLR' },
                            rpcUrls: ['https://coston-api.flare.network/ext/bc/C/rpc'],
                            blockExplorerUrls: ['https://coston-explorer.flare.network/']
                        }]
                });
            }
            UseCOSTONnetwork();
        }
    }

export async function switchtoSGB() {
        const chainId = 19 // 19 is SGB; change to 16 when using Coston
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.toHex(chainId) }]
            });

            //store the new chain name in local storage to show user
            localStorage.setItem('chainNameCard', 'you were switched to Songbird');

        } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: 'Songbird',
                            chainId: web3.utils.toHex(chainId),
                            nativeCurrency: { name: 'Songbird', decimals: 18, symbol: 'SGB' },
                            rpcUrls: ['https://songbird-api.flare.network/ext/C/rpc'],
                            blockExplorerUrls: ['https://songbird-explorer.flare.network/']
                        }]
                });
            }
            UseSGBnetwork();
        }
    }

export async function switchtoFLR() {
        const chainId = 14 // Flare Network Id
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.toHex(chainId) }]
            });

            //store the new chain name in local storage to show user
            localStorage.setItem('chainNameCard', 'you were switched to Flare');

        } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: 'Flare',
                            chainId: web3.utils.toHex(chainId),
                            nativeCurrency: { name: 'FLARE', decimals: 18, symbol: 'FLR' },
                            rpcUrls: ['https://flare-api.flare.network/ext/C/rpc'],
                            blockExplorerUrls: ['https://flare-explorer.flare.network/']
                        }]
                });
            }
            UseFLRnetwork();
        }
}
//________________________________________________________________________________________________________________________________

