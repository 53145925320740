import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";


import './index.css';
import Perk from "./factory.js";
import './factory.css';
import PerkLink from "./factory-link.js";
import PerkTokens from "./factory-tokens.js";




export default function App() {


    return (
        <BrowserRouter >
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Perk />} />
                    <Route path="/factory-link/:type/:network/:id"  element={<PerkLink />} />
                    <Route path="/factory-tokens"  element={<PerkTokens />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);