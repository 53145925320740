import './factory.css';
import { ethers } from "ethers";

import ABI_FactoryLibrary from "./ABI_FactoryLibrary.json";
import ABI_FactoryMarket from "./ABI_FactoryMarket.json";
import ABI_FactoryMixer from "./ABI_FactoryMixer.json";
import ABI_P2PTrade from "./ABI_FactoryP2PTrade.json"; // Verify filename matches your project

import ABI_PERKToken from "./ABI_TokenPERK.json"; // Verify filename
import ABI_WSGBToken from "./ABI_TokenWSGB.json"; // Verify filename
import ABI_WFLRToken from "./ABI_TokenWFLR.json"; // Verify filename



// Export contract creators that take provider/signer as arguments
export const createProvider = () => {
    if (!window.ethereum) throw new Error("No Ethereum provider detected");
    return new ethers.providers.Web3Provider(window.ethereum);
};

export const createSigner = (provider) => provider.getSigner();

//________________________________________________________________________________________________________________________________
// Token contracts
export const PERKTokenCoston = "0xaAf5e6f9aab4135ABa7F82C132E83774F1Dd77f1";
export const createPERKTokenCoston = (signer) => new ethers.Contract(PERKTokenCoston, ABI_PERKToken, signer);

export const PERKTokenSGB = "0x";
export const createPERKTokenSGB = (signer) => new ethers.Contract(PERKTokenSGB, ABI_PERKToken, signer);

export const PERKTokenFLR = "0x";
export const createPERKTokenFLR = (signer) => new ethers.Contract(PERKTokenFLR, ABI_PERKToken, signer);

export const TokenWSGB = "0x02f0826ef6aD107Cfc861152B32B52fD11BaB9ED";
export const createWSGBToken = (signer) => new ethers.Contract(TokenWSGB, ABI_WSGBToken, signer);

export const TokenWFLR = "0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d";
export const createWFLRToken = (signer) => new ethers.Contract(TokenWFLR, ABI_WFLRToken, signer);

//________________________________________________________________________________________________________________________________
// Coston network contracts
export const FactoryLibraryCOSTON = "0xa090755c9BFeBC378d70dBC5a565Ed77E1760b9B";
export const FactoryMarketCOSTON = "0xfE23BEFCA364Ce0356Ee18A73EE931e93E8d37A1";
export const FactoryMixerCOSTON = "0xe998F50dA5A7fFd5dAfAFAd80e383C77d2b8898F";
export const FactoryP2PTradeCOSTON = "0x1c37fc017ED485e454A02EeD792F41013596e4b1";

export const createFactoryLibraryCOSTON_SignN = (provider) => new ethers.Contract(FactoryLibraryCOSTON, ABI_FactoryLibrary, provider);
export const createFactoryLibraryCOSTON_SignY = (signer) => new ethers.Contract(FactoryLibraryCOSTON, ABI_FactoryLibrary, signer);

export const createCOSTONFactoryMarket_SignN = (provider) => new ethers.Contract(FactoryMarketCOSTON, ABI_FactoryMarket, provider);
export const createCOSTONFactoryMarket_SignY = (signer) => new ethers.Contract(FactoryMarketCOSTON, ABI_FactoryMarket, signer);

export const createCOSTONFactoryMixer_SignN = (provider) => new ethers.Contract(FactoryMixerCOSTON, ABI_FactoryMixer, provider);
export const createCOSTONFactoryMixer_SignY = (signer) => new ethers.Contract(FactoryMixerCOSTON, ABI_FactoryMixer, signer);

export const createP2PTradeContractCoston_SignN = (provider) => new ethers.Contract(FactoryP2PTradeCOSTON, ABI_P2PTrade, provider);
export const createP2PTradeContractCoston_SignY = (signer) => new ethers.Contract(FactoryP2PTradeCOSTON, ABI_P2PTrade, signer);

//________________________________________________________________________________________________________________________________
// Songbird network contracts
export const FactoryLibrarySGB = "0x";
export const FactoryMarketSGB = "0x";
export const FactoryMixerSGB = "0x";
export const FactoryP2PTradeSGB = "0x"; // Replace after deployment

export const createFactoryLibrarySGB_SignN = (provider) => new ethers.Contract(FactoryLibrarySGB, ABI_FactoryLibrary, provider);
export const createFactoryLibrarySGB_SignY = (signer) => new ethers.Contract(FactoryLibrarySGB, ABI_FactoryLibrary, signer);

export const createSGBFactoryMarket_SignN = (provider) => new ethers.Contract(FactoryMarketSGB, ABI_FactoryMarket, provider);
export const createSGBFactoryMarket_SignY = (signer) => new ethers.Contract(FactoryMarketSGB, ABI_FactoryMarket, signer);

export const createSGBFactoryMixer_SignN = (provider) => new ethers.Contract(FactoryMixerSGB, ABI_FactoryMixer, provider);
export const createSGBFactoryMixer_SignY = (signer) => new ethers.Contract(FactoryMixerSGB, ABI_FactoryMixer, signer);

export const createSGBP2PTrade_SignN = (provider) => new ethers.Contract(FactoryP2PTradeSGB, ABI_P2PTrade, provider);
export const createSGBP2PTrade_SignY = (signer) => new ethers.Contract(FactoryP2PTradeSGB, ABI_P2PTrade, signer);

//________________________________________________________________________________________________________________________________
// Flare network contracts
export const FactoryLibraryFLR = "0x";
export const FactoryMarketFLR = "0x";
export const FactoryMixerFLR = "0x";
export const FactoryP2PTradeFLR = "0x"; // Replace after deployment

export const createFactoryLibraryFLR_SignN = (provider) => new ethers.Contract(FactoryLibraryFLR, ABI_FactoryLibrary, provider);
export const createFactoryLibraryFLR_SignY = (signer) => new ethers.Contract(FactoryLibraryFLR, ABI_FactoryLibrary, signer);

export const createFLRFactoryMarket_SignN = (provider) => new ethers.Contract(FactoryMarketFLR, ABI_FactoryMarket, provider);
export const createFLRFactoryMarket_SignY = (signer) => new ethers.Contract(FactoryMarketFLR, ABI_FactoryMarket, signer);

export const createFLRFactoryMixer_SignN = (provider) => new ethers.Contract(FactoryMixerFLR, ABI_FactoryMixer, provider);
export const createFLRFactoryMixer_SignY = (signer) => new ethers.Contract(FactoryMixerFLR, ABI_FactoryMixer, signer);

export const createFLRP2PTrade_SignN = (provider) => new ethers.Contract(FactoryP2PTradeFLR, ABI_P2PTrade, provider);
export const createFLRP2PTrade_SignY = (signer) => new ethers.Contract(FactoryP2PTradeFLR, ABI_P2PTrade, signer);

//________________________________________________________________________________________________________________________________

