import React from 'react';
import './disclaimerModal.css';

const DisclaimerModal = ({ isOpen, onAgree, onCancel, title = "Disclaimer", line1, line2, line3, line4 }) => {
    if (!isOpen) return null;

    return (
        <div className="disclaimer-overlay">
            <div className="disclaimer-modal">
                <h2>{title}</h2>
                <p>{line1 || "Default line 1 text."}</p>
                <p>{line2 || "Default line 2 text."}</p>
                <p>{line3 || "Default line 3 text."}</p>
                <p>{line4 || "Default line 4 text."}</p>

                <div className="disclaimer-buttons">
                    <button className="disclaimer-agree" onClick={onAgree}>I Agree</button>
                    <button className="disclaimer-cancel" onClick={onCancel}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default DisclaimerModal;