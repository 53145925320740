import React, { useState, useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import { ethers } from "ethers";
import Select from "react-select";
import FactoryCard from "./factory-card.js";
import * as contractModule from "./factoryvariables-contracts.js";
import * as networkModule from "./factoryvariables-networks.js";
import * as tokenModule from "./factoryvariables-tokens.js";
import * as feesModule from "./factoryvariables-fees.js";
import "./factory.css";
import DisclaimerModal from './disclaimerModal.js'; // Adjust the import path as needed


class Perk extends React.Component {
    constructor(props) {
        super(props);
        this.state = { visible: false, fadingOut: false, loading: true };
    }

    show() {
        console.log("Card button clicked - attempting to show overlay");
        console.log('Viewport dimensions:', window.innerWidth, window.innerHeight);
        this.setState({ visible: true, fadingOut: false, loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 500);
    }

    hide() {
        console.log("Overlay closing");
        this.setState({ fadingOut: true });
        setTimeout(() => {
            this.setState({ visible: false, fadingOut: false, loading: true });
            if (this.props.onModalClose) {
                this.props.onModalClose.current(this.props.setShowCardButton);
            }
        }, 500);
    }

    handleBackgroundClick(e) {
        if (e.target.classList.contains("back-Mainoverlay")) {
            this.hide();
        }
    }

    render() {
        const { visible, fadingOut, loading } = this.state;
        const { showCardButton, setShowCardButton, cardHelp, setCardHelp } = this.props;

        const overlayContent = (
            <div
                className={`back-Mainoverlay ${fadingOut ? 'fade-out' : 'fade-in'}`}
                style={{
                    zIndex: 10000,
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                    margin: 0,
                    padding: 0,
                }}
                onClick={this.handleBackgroundClick.bind(this)}
            >
                <div
                    className="factory-card-container"
                    style={{
                        backgroundColor: 'transparent',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 10001,
                        padding: '0',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {loading ? (
                        <div className="loading">Loading card...</div>
                    ) : (
                        <FactoryCard cardHelp={cardHelp} setCardHelp={setCardHelp} isPreview={true} />
                    )}
                    <div
                        className="overlay-close"
                        onClick={this.hide.bind(this)}
                        style={{ cursor: "pointer", zIndex: 10002 }}
                    >
                        ✕
                    </div>
                </div>
            </div>
        );

        return (
            <div className="App">
                {showCardButton && (
                    <div>
                        <div
                            id="helpMode"
                            className="btn-on"
                            onClick={this.show.bind(this)}
                            style={{ cursor: "pointer", pointerEvents: "auto", zIndex: 3000 }}
                        >
                            card
                        </div>
                        {visible && ReactDOM.createPortal(overlayContent, document.body)}
                    </div>
                )}
                <MyFunctions
                    showCardButton={showCardButton}
                    setShowCardButton={setShowCardButton}
                    onModalClose={this.props.onModalClose}
                    perkInstance={this}
                    cardHelp={cardHelp}
                    setCardHelp={setCardHelp}
                />
            </div>
        );
    }
}

function MyFunctions({ showCardButton, setShowCardButton, onModalClose, perkInstance, cardHelp, setCardHelp }) {
    const [claimButtonText, setclaimButtonText] = useState("claim");
    const [p2pButtonText, setp2pButtonText] = useState("finalize");
    const [chainNameCard, setChainNameCard] = useState(localStorage.getItem('chainNameCard'));
    const [showMenu, setShowMenu] = useState(false);
    const [showMenuOptions, setShowMenuOptions] = useState(false);
    const [showComingSoon, setShowComingSoon] = useState(false);
    const [showWelcome, setShowWelcome] = useState(true);
    const [showDarkMode, setShowDarkMode] = useState(false);
    const [showstartButton, setShowstartButton] = useState(false);
    const [showPERKsamt, setShowPERKsamt] = useState(false);
    const [showAIMessage, setShowAIMessage] = useState("exclusively on Flare Networks");
    const [transactionHash, setTransactionHash] = useState("");
    const [transactionLink, setTransactionLink] = useState("");
    const [resultMessage, setResultMessage] = useState("");
    const [result, setResult] = useState("");
    const [error, setError] = useState("");
    const [startButtonText, setStartButtonText] = useState("start");
    const [startButtonColor, setStartButtonColor] = useState("transparent");
    const [balance, setBalance] = useState("");
    const [fadingOutApprove, setFadingOutApprove] = useState(false);
    const [connected, setConnected] = useState(false);
    const [currentAddress, setCurrentAddress] = useState("");
    const [showGenerator, setShowGenerator] = useState(false);
    const [showGenSelector, setShowGenSelector] = useState(false);
    const [showGentype, setShowGentype] = useState("");
    const [showGenemail, setShowGenemail] = useState(false);
    const [showGenname, setShowGenname] = useState(false);
    const [showGentitle, setShowGentitle] = useState(false);
    const [showGendescription, setShowGendescription] = useState(false);
    const [showGenwebsite, setShowGenwebsite] = useState(false);
    const [showGenrecipient, setShowGenrecipient] = useState(false);
    const [showGentoken1, setShowGentoken1] = useState(false);
    const [showGentoken2, setShowGentoken2] = useState(false);
    const [showGentoken3, setShowGentoken3] = useState(false);
    const [showGentags, setShowGentags] = useState(false);
    const [showGenimage, setShowGenimage] = useState(false);
    const [showPreviewButton, setShowPreviewButton] = useState(false);
    const [showGensubmit, setShowGensubmit] = useState(false);
    const [showGensuccess, setShowGensuccess] = useState(false);
    const [showPaymentOptions, setShowPaymentOptions] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedToken1, setSelectedToken1] = useState("");
    const [selectedToken2, setSelectedToken2] = useState("");
    const [selectedToken3, setSelectedToken3] = useState("");
    const [showMixer, setShowMixer] = useState(false);
    const [showMixAmount, setShowMixAmount] = useState(false);
    const [mixToken1, setMixToken1] = useState("");
    const [claimToken1, setClaimToken1] = useState("");
    const [showMixDestination, setShowMixDestination] = useState(false);
    const [showMixSelector, setShowMixSelector] = useState(false);
    const [showClaimSelector, setShowClaimSelector] = useState(false);
    const [showMixSubmit, setShowMixSubmit] = useState(false);
    const [showClaimMode, setShowClaimMode] = useState(false);
    const [showApproveButton, setShowApproveButton] = useState(false);
    const [showApproveMessages, setShowApproveMessages] = useState("");
    const [showDYKmessage, setShowDYKmessage] = useState("");
    const [selectedPaymentToken, setSelectedPaymentToken] = useState("");
    const [mixAmount, setMixAmount] = useState("");
    const [mixDestination, setMixDestination] = useState("");
    const [provider, setProvider] = useState(null);
    const [signer, setSigner] = useState(null);

    const { payCardFeeWSGB, payCardFeeWFLR, payCardFeePERKCFLR, payCardFeePERKSGB, payCardFeePERKFLR } = feesModule.useFees();

    const menuRef = useRef(null);
    const [showDisclaimer, setShowDisclaimer] = useState(false); // Existing state for P2P disclaimer
    const [showMixerDisclaimer, setShowMixerDisclaimer] = useState(false); // New state for mixer disclaimer

    // New P2P states
    const [showP2PTrade, setShowP2PTrade] = useState(false);
    const [showP2PToken1, setshowP2PToken1] = useState(false);
    const [showP2PToken2, setshowP2PToken2] = useState(false);
    const [showP2PUser2, setshowP2PUser2] = useState(false);
    const [showP2PTradeButton, setshowP2PTradeButton] = useState(false);
    const [showP2PUncompleted, setshowP2PUncompleted] = useState(false);
    const [showP2PFinalButton, setshowP2PFinalButton] = useState(false);
    const [p2ptoken1, setP2Ptoken1] = useState("");
    const [p2ptoken2, setP2Ptoken2] = useState("");
    const [p2pAmount1, setP2PAmount1] = useState("");
    const [p2pAmount2, setP2PAmount2] = useState("");
    const [p2pUser2, setp2pUser2] = useState("");
    const [p2pAgreementCode, setP2PAgreementCode] = useState("");
    const [showP2PSelectedTrade, setshowP2PSelectedTrade] = useState(null);
    const [p2pInputAgreementCode, setP2PInputAgreementCode] = useState("");


    const options = [{ value: "receive", label: "receive" }];

    const handleMenuOpen = () => {
        setTimeout(() => {
            const menu = document.querySelector('.react-select__menu');
            if (menu) {
                // Check if the menu is scrollable
                const isScrollable = menu.scrollHeight > menu.clientHeight;
                if (isScrollable) {
                    menu.classList.add('has-scroll');
                } else {
                    menu.classList.remove('has-scroll');
                }
            }
        }, 0); // Use setTimeout to ensure the menu is rendered
    };

    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    const getDefaultMode = () => localStorage.getItem("mode") || "WhiteMode";
    const [mode, setMode] = useState(getDefaultMode());

    useEffect(() => {
        console.log("Applying mode:", mode);
        document.body.classList.remove("WhiteMode", "DarkMode");
        document.body.classList.add(mode);
        localStorage.setItem("mode", mode);
        window.scrollTo(0, 0)
    }, [mode]);

    const handleToggleDarkMode = () => {
        console.log("Dark mode clicked");
        const newMode = mode === "WhiteMode" ? "DarkMode" : "WhiteMode";
        setMode(newMode);
    };

    useEffect(() => {
        const init = async () => {
            try {
                console.log("Starting init...");
                setShowCardButton(false);
                await delay(2000);
                setShowWelcome(false);
                await delay(500);
                setShowAIMessage("developed and audited with AI");
                setShowWelcome(true);
                await delay(2000);
                setShowWelcome(false);
                await delay(545);
                await getPERKsbalance();
                setShowPERKsamt(true);
                setShowstartButton(true);
                setShowDarkMode(true);
                setShowGenSelector(false);

                localStorage.setItem('chainNameCard', "");
                console.log("Init complete");
            } catch (err) {
                console.error("Init failed:", err);
                setError("error: useEffect initialization failed...");
            }
        };
        init();

        const handleChainChanged = () => window.location.reload();
        window.ethereum?.on("chainChanged", handleChainChanged);
        window.ethereum?.on("accountsChanged", handleChainChanged);
        return () => {
            window.ethereum?.off("chainChanged", handleChainChanged);
            window.ethereum?.off("accountsChanged", handleChainChanged);
        };
    }, []);

    const getPERKsbalance = async () => {
        try {
            if (!window.ethereum) {
                setBalance("please use a web3 wallet to access this site...");
                console.log("No Ethereum provider detected");
                return;
            }
            const newProvider = contractModule.createProvider();
            setProvider(newProvider);
            const newSigner = contractModule.createSigner(newProvider);
            setSigner(newSigner);

            const accounts = await newProvider.listAccounts();
            if (accounts.length === 0) {
                setBalance("please connect your wallet...");
                console.log("No accounts connected");
                return;
            }

            const chain = await newProvider.getNetwork();
            const chainId = chain.chainId;
            const address = await newSigner.getAddress();
            setCurrentAddress(address);

            let balance;
            if (chainId === 16) {
                if (contractModule.PERKTokenCoston === "0x") {
                    setBalance("PERK token not deployed on Coston");
                    return;
                }
                const contract = contractModule.createPERKTokenCoston(newSigner);
                balance = await contract.balanceOf(address);
                setBalance(`you have ${new Intl.NumberFormat("en-US").format(balance / 10 ** 18)} PERKS on Coston`);
            } else if (chainId === 19) {
                if (contractModule.PERKTokenSGB === "0x") {
                    setBalance("PERK token not deployed on Songbird");
                    return;
                }
                const contract = contractModule.createPERKTokenSGB(newSigner);
                balance = await contract.balanceOf(address);
                setBalance(`you have ${new Intl.NumberFormat("en-US").format(balance / 10 ** 18)} PERKS on Songbird`);
            } else if (chainId === 14) {
                if (contractModule.PERKTokenFLR === "0x") {
                    setBalance("PERK token not deployed on Flare");
                    return;
                }
                const contract = contractModule.createPERKTokenFLR(newSigner);
                balance = await contract.balanceOf(address);
                setBalance(`you have ${new Intl.NumberFormat("en-US").format(balance / 10 ** 18)} PERKS on Flare`);
            } else {
                setBalance("unsupported network");
                console.log(`unsupported chainId: ${chainId}`);
                return;
            }
            console.log("Balance fetched successfully:", balance.toString());
        } catch (err) {
            setError("error: failed to fetch PERKs balance...");
            setBalance("");
            console.error("PERKs balance fetch failed:", err);
        }
    };

    const StartFunction = () => {
        console.log("Start button clicked, current text:", startButtonText);
        if (startButtonText === "close") {
            setStartButtonText("start");
            setStartButtonColor("transparent");
            setShowMenu(false);
            setShowMenuOptions(false);
            setShowGenerator(false);
            setShowMixer(false);
            setShowP2PTrade(false);
            setP2Ptoken1("");
            setP2Ptoken2("");
            setP2PAmount1("");
            setP2PAmount2("");
            setp2pUser2("");
            setP2PAgreementCode("");
            setshowP2PSelectedTrade(null);
            setShowMixAmount(false);
            setShowMixSelector(false);
            setShowMixDestination(false);
            setShowMixSubmit(false);
            setShowGenSelector(false);
            setShowGentype("");
            setSelectedOption("");
            setShowGenemail(false);
            setShowGenname(false);
            setShowGentitle(false);
            setShowGendescription(false);
            setShowGenwebsite(false);
            setShowGenrecipient(false);
            setShowGentoken1(false);
            setShowGentoken2(false);
            setShowGentoken3(false);
            setShowGentags(false);
            setShowGenimage(false);
            setShowPreviewButton(false);
            setShowGensubmit(false);
            setShowGensuccess(false);
            setShowPaymentOptions(false);
            setShowPERKsamt(true);
            setShowComingSoon(false);
            setTransactionHash("");
            setTransactionLink("");
            setResultMessage("");
            setResult("");
            setError("");
            setSelectedToken1("");
            setSelectedToken2("");
            setSelectedToken3("");
            setMixToken1("");
            setSelectedPaymentToken("");
            setShowCardButton(false);
            window.scrollTo(0, 0);
        } else {
            setStartButtonText("close");
            setStartButtonColor("#e61f57");
            setShowPERKsamt(false);
            setShowMenu(true);
            setShowMenuOptions(true);
            setShowPreviewButton(false);
            setShowGensuccess(false);
            setShowComingSoon(false);
            setTransactionHash("");
            setResult("");
            setError("");
        }
    };

    const clickGenerator = () => {
        console.log("Cards button clicked");
        setShowMenu(false);
        setShowMenuOptions(false);
        setShowGenerator(true);
        setShowMixer(false);
        setShowP2PTrade(false);
        setShowGenSelector(true);
        setShowGensubmit(false);
        setShowPERKsamt(true);
        setShowPreviewButton(false);
        setShowCardButton(true);
        setShowComingSoon(false);
    };

    const showDonationForm = () => {
        console.log("Showing donation form");
        setShowGenerator(true);
        setShowGenSelector(false);
        setShowGenemail(true);
        setShowGenname(true);
        setShowGentitle(true);
        setShowGendescription(true);
        setShowGenwebsite(true);
        setShowGenrecipient(true);
        setShowGentoken1(true);
        setShowGentoken2(true);
        setShowGentoken3(true);
        setShowGentags(true);
        setShowGenimage(true);
        setShowPreviewButton(true);
        setShowGensubmit(false);
        setShowGensuccess(false);
        setShowPaymentOptions(false);
    };

    const clickPreview = () => {
        console.log("Preview button clicked - showing card overlay");
        perkInstance.show();
        setShowGensubmit(true);
    };

    const clickMixer = () => {
        console.log("Mixer button clicked - showing disclaimer");
        setShowMixerDisclaimer(true); // Show the mixer disclaimer modal
    };

    const handleMixerDisclaimerAgree = () => {
        setShowMixerDisclaimer(false); // Close the modal
        console.log("User agreed to mixer disclaimer - proceeding with mixer setup");
        setShowMenu(false);
        setShowMenuOptions(false);
        setShowGenerator(false);
        setShowMixer(true);
        setShowP2PTrade(false);
        setShowMixAmount(true);
        setShowMixSelector(true);
        setShowMixDestination(true);
        setShowMixSubmit(true);
        setShowGenSelector(false);
        setShowPERKsamt(true);
        setShowComingSoon(false);
    };

    const clickP2P = () => { // Remove `async` since there's no await
        console.log("P2P button clicked - showing disclaimer");
        setShowDisclaimer(true); // Show the disclaimer modal
    };

    const handleDisclaimerAgree = () => {
        setShowDisclaimer(false); // Close the modal
        console.log("User agreed to disclaimer - proceeding with P2P trade setup");
        // Proceed with the original clickP2P logic to set up the P2P trade UI
        setShowMenu(false);
        setShowMenuOptions(false);
        setShowGenerator(false);
        setShowMixer(false);
        setShowP2PTrade(true);
        setshowP2PToken1(true);
        setshowP2PToken2(true);
        setshowP2PUser2(true);
        setshowP2PTradeButton(true);
        setShowComingSoon(false);
        setShowPERKsamt(true);
    };

    const comingSoon = () => {
        setShowMenuOptions(false);
        setShowComingSoon(true);
    };

    const showPerkLinkSuccess = () => {
        console.log("Showing success message");
        setShowGenerator(false);
        setShowGenemail(false);
        setShowGenname(false);
        setShowGentitle(false);
        setShowGendescription(false);
        setShowGenwebsite(false);
        setShowGenrecipient(false);
        setShowGentoken1(false);
        setShowGentoken2(false);
        setShowGentoken3(false);
        setShowGentags(false);
        setShowGenimage(false);
        setShowPreviewButton(false);
        setShowGensubmit(false);
        setShowGensuccess(true);
        setShowPaymentOptions(false);
    };

    const handleTypeSelect = (e) => {
        console.log("Type selected, value:", e ? e.value : "null");
        if (e) {
            setSelectedOption(e.value);
            setShowGentype(e.value);
            setShowPaymentOptions(true);
        }
    };

    const handleTokenSelect1 = (e) => {
        console.log("Token 1 selected:", e.value);
        setSelectedToken1(e.value);
        setCardHelp(prev => ({ ...prev, token1: e.value }));
        localStorage.setItem("help-perk_token1", e.value);
    };

    const handleTokenSelect2 = (e) => {
        console.log("Token 2 selected:", e.value);
        setSelectedToken2(e.value);
        setCardHelp(prev => ({ ...prev, token2: e.value }));
        localStorage.setItem("help-perk_token2", e.value);
    };

    const handleTokenSelect3 = (e) => {
        console.log("Token 3 selected:", e.value);
        setSelectedToken3(e.value);
        setCardHelp(prev => ({ ...prev, token3: e.value }));
        localStorage.setItem("help-perk_token3", e.value);
    };

    const handlePaymentTokenSelect = (e) => {
        console.log("Payment token selected:", e.value);
        tokenModule.CheckTokenNetwork(e.value);
        setSelectedPaymentToken(e.value);
        showDonationForm();
    };

    const handleP2Ptoken1 = (e) => {
        console.log("P2P token1 selected:", e.value);
        tokenModule.CheckTokenNetwork(e.value);
        setP2Ptoken1(e.value);
    };

    const handleP2Ptoken2 = (e) => {
        console.log("P2P token2 selected:", e.value);
        tokenModule.CheckTokenNetwork(e.value);
        setP2Ptoken2(e.value);
    };


    const handleInitiateP2PTrade = async () => {
        console.log("Initiating P2P Trade with tokens:", p2ptoken1, p2ptoken2, "amounts:", p2pAmount1, p2pAmount2, "user2:", p2pUser2);
        setShowApproveMessages("please initiate the trade...");
        setShowDYKmessage("did you know? \n\n this allows you to trade tokens with others in a decentralized, secure, trustless manner... fiat trades require User2 to lock funds and User1 to finalize");
        setShowApproveButton(true);

        try {
            if (!provider || !signer) {
                const newProvider = contractModule.createProvider();
                await newProvider.send("eth_requestAccounts", []);
                const newSigner = contractModule.createSigner(newProvider);
                setProvider(newProvider);
                setSigner(newSigner);
            }
            const chainId = window.ethereum?.networkVersion || "16";
            let p2pContract;
            switch (chainId) {
                case "16": p2pContract = contractModule.createP2PTradeContractCoston_SignY(signer); break;
                case "19": p2pContract = contractModule.createSGBP2PTrade_SignY(signer); break;
                case "14": p2pContract = contractModule.createFLRP2PTrade_SignY(signer); break;
                default: throw new Error(`Unsupported chainId: ${chainId}`);
            }

            const token1 = tokenModule.tokenOptions.find(opt => opt.value === p2ptoken1);
            const token2 = tokenModule.tokenOptions.find(opt => opt.value === p2ptoken2);
            console.log("token1:", token1, "token2:", token2); // Debug log
            if (!token1 || !token2) throw new Error("Invalid token selection...");
            const amount1 = ethers.utils.parseUnits(p2pAmount1 || "0", token1.decimals);
            const amount2 = ethers.utils.parseUnits(p2pAmount2 || "0", token2.decimals);
            const user2 = p2pUser2;
            const agreementCode = ethers.utils.hexlify(ethers.utils.randomBytes(32));
            const senderAddress = await signer.getAddress();

            if (token1.value === "fiat") throw new Error("User1 cannot initiate with fiat...");

            let tx;
            const token1Address = token1.address;
            const token2Address = token2.value === "fiat" ? "0x1000000000000000000000000000000000000000" : token2.address;
            console.log("token1Address:", token1Address, "token2Address:", token2Address); // Debug log
            if (token1Address === "0x0000000000000000000000000000000000000000") {
                const balance = await provider.getBalance(senderAddress);
                if (balance.lt(amount1)) throw new Error(`insufficient ${token1.value} value balance...`);
                tx = await p2pContract.initiateTrade(user2, token1Address, amount1, token2Address, amount2, agreementCode, { value: amount1, gasLimit: 3000000 });
            } else {
                const tokenContract = token1.createContract ? token1.createContract(signer) : null;
                if (!tokenContract) throw new Error(`no contract factory for ${token1.value}...`);
                const balance = await tokenContract.balanceOf(senderAddress);
                if (balance.lt(amount1)) throw new Error(`insufficient ${token1.value} balance...`);
                const allowance = await tokenContract.allowance(senderAddress, p2pContract.address);
                if (allowance.lt(amount1)) {
                    setShowApproveMessages("please approve the contract...");
                    const approveTx = await tokenContract.approve(p2pContract.address, ethers.constants.MaxUint256, { gasLimit: 3000000 });
                    await approveTx.wait();
                    setShowApproveMessages("initiating trade...");
                }
                tx = await p2pContract.initiateTrade(user2, token1Address, amount1, token2Address, amount2, agreementCode, { gasLimit: 3000000 });
            }

            const receipt = await tx.wait();
            setTransactionHash(receipt.transactionHash);
            setTransactionLink(localStorage.getItem('explorerLink') + receipt.transactionHash);
            setShowApproveButton(false);
            setResultMessage("please give this transaction hash to the other person to lock or finalize the trade \n\n or you can return in 3 hours to withdraw your tokens and cancel...");
            localStorage.setItem(`trade_${receipt.transactionHash}`, JSON.stringify({ agreementCode }));
            setShowP2PTrade(false);
            window.scrollTo(0, 0);
        } catch (err) {
            console.error("P2P trade initiation failed:", err);
            setShowApproveMessages(`error: ${err.message}`);
            setError(`error: ${err.message}`);
        }
    };

    const handleCompleteP2PTrade = async () => {
        console.log("Completing P2P Trade with input:", p2pInputAgreementCode);
        setShowApproveMessages("please finalize the trade...");
        setShowDYKmessage("did you know? \n\n the user who created the transaction will release the tokens upon confirmation in the app after the other user locks the tokens with his/her confirmation [for fiat]; for token-to-token transfers the confirmation by user 2 automatically sends the funds to each recipient");
        setShowApproveButton(true);

        try {
            if (!provider || !signer) {
                const newProvider = contractModule.createProvider();
                await newProvider.send("eth_requestAccounts", []);
                const newSigner = contractModule.createSigner(newProvider);
                setProvider(newProvider);
                setSigner(newSigner);
            }
            const chainId = window.ethereum?.networkVersion || "16";
            let p2pContract;
            switch (chainId) {
                case "16": p2pContract = contractModule.createP2PTradeContractCoston_SignY(signer); break;
                case "19": p2pContract = contractModule.createSGBP2PTrade_SignY(signer); break;
                case "14": p2pContract = contractModule.createFLRP2PTrade_SignY(signer); break;
                default: throw new Error(`Unsupported chainId: ${chainId}`);
            }

            if (!p2pInputAgreementCode || !ethers.utils.isHexString(p2pInputAgreementCode)) {
                throw new Error("invalid transaction hash...");
            }
            const storedTrade = localStorage.getItem(`trade_${p2pInputAgreementCode}`);
            if (!storedTrade) throw new Error("no trade found for this transaction hash...");
            const { agreementCode } = JSON.parse(storedTrade);
            if (!ethers.utils.isHexString(agreementCode, 32)) throw new Error("invalid agreement code in storage...");

            const senderAddress = await signer.getAddress();

            const [tradeIds, tradeDetails] = await p2pContract.getUncompletedTrades();
            if (!tradeIds || !tradeDetails || !Array.isArray(tradeIds) || tradeDetails.length === 0) {
                throw new Error("failed to fetch uncompleted trades...");
            }

            const validTrades = tradeDetails.map((trade, index) => ({
                tradeId: tradeIds[index].toNumber(),
                user1: trade.user1,
                user2: trade.user2,
                token1: trade.token1,
                token1Amount: trade.token1Amount.toString(),
                token2: trade.token2,
                token2Amount: trade.token2Amount.toString(),
                user1Deposit: trade.user1Deposit.toString(),
                user2Deposit: trade.user2Deposit.toString(),
                user1Confirmed: trade.user1Confirmed,
                user2Confirmed: trade.user2Confirmed,
                completed: trade.completed,
                agreementCode: trade.agreementCode,
                createdAt: trade.createdAt.toNumber() // Fetch the creation timestamp
            }));
            console.log("Uncompleted trades:", validTrades);

            const userTrades = validTrades.filter(t =>
                t.user1.toLowerCase() === senderAddress.toLowerCase() ||
                t.user2.toLowerCase() === senderAddress.toLowerCase()
            );
            if (userTrades.length === 0) throw new Error("no uncompleted trades found for your address...");

            const trade = userTrades.find(t => t.agreementCode.toLowerCase() === agreementCode.toLowerCase());
            if (!trade) throw new Error("no uncompleted trade found for this code...");

            const token2Address = trade.token2.toLowerCase();
            const amount2 = ethers.BigNumber.from(trade.token2Amount);
            console.log("token2Address:", token2Address); // Debug log
            const token2 = tokenModule.tokenOptions.find(opt => opt.address.toLowerCase() === token2Address);
            console.log("token2:", token2); // Debug log
            if (!token2) throw new Error("Trade token not found...");

            let tx;
            if (token2Address === "0x1000000000000000000000000000000000000000") { // FIAT_ADDRESS
                if (senderAddress.toLowerCase() === trade.user2.toLowerCase() && !trade.user2Confirmed) {
                    tx = await p2pContract.completeTrade(trade.tradeId, agreementCode, { gasLimit: 3000000 });
                    setResultMessage("fiat payment confirmed; funds locked, cancellation disabled, notify the other person to finalize...");
                } else if (senderAddress.toLowerCase() === trade.user1.toLowerCase() && !trade.user1Confirmed) {
                    tx = await p2pContract.completeTrade(trade.tradeId, agreementCode, { gasLimit: 3000000 });
                    setResultMessage("trade finalized; tokens released to User2...");
                } else {
                    throw new Error("already confirmed or invalid user...");
                }
            } else {
                if (senderAddress.toLowerCase() !== trade.user2.toLowerCase()) throw new Error("only User2 can complete token trades...");
                const isNativeToken = token2.address === "0x0000000000000000000000000000000000000000";
                const tokenContract = token2.createContract ? token2.createContract(signer) : null;

                if (isNativeToken) {
                    const balance = await provider.getBalance(senderAddress);
                    if (balance.lt(amount2)) throw new Error(`insufficient ${token2.value} balance...`);
                    tx = await p2pContract.completeTrade(trade.tradeId, agreementCode, { value: amount2, gasLimit: 3000000 });
                } else {
                    if (!tokenContract) throw new Error(`no contract factory for ${token2.value}...`);
                    const balance = await tokenContract.balanceOf(senderAddress);
                    if (balance.lt(amount2)) throw new Error(`insufficient ${token2.value} balance...`);
                    const allowance = await tokenContract.allowance(senderAddress, p2pContract.address);
                    if (allowance.lt(amount2)) {
                        setShowApproveMessages("please approve the contract...");
                        const approveTx = await tokenContract.approve(p2pContract.address, ethers.constants.MaxUint256, { gasLimit: 3000000 });
                        await approveTx.wait();
                        setShowApproveMessages("completing trade...");
                    }
                    tx = await p2pContract.completeTrade(trade.tradeId, agreementCode, { gasLimit: 3000000 });
                }
                setResultMessage("trade completed successfully...");
            }

            const receipt = await tx.wait();
            setTransactionHash(receipt.transactionHash);
            setTransactionLink(localStorage.getItem('explorerLink') + receipt.transactionHash);
            setShowApproveButton(false);
            setP2PInputAgreementCode("");
            setResultMessage("the tokens are disbursed to the provided wallet[s] after confirmation is received...");
            localStorage.setItem(`trade_${receipt.transactionHash}`, JSON.stringify({ agreementCode }));
            setShowP2PTrade(false);
            window.scrollTo(0, 0);

            const initiationTxHash = Object.keys(localStorage).find(key =>
                key.startsWith("trade_") && JSON.parse(localStorage[key]).agreementCode === agreementCode
            );
            if (initiationTxHash) {
                localStorage.removeItem(initiationTxHash);
                console.log("Removed completed trade from localStorage:", initiationTxHash);
            }
        } catch (err) {
            console.error("P2P trade completion failed:", err);
            setShowApproveMessages(`error: ${err.message}`);
            setError(`error: ${err.message}`);
        }
    };

    const handleCancelP2PTrade = async () => {
        console.log("Cancelling P2P Trade with input:", p2pInputAgreementCode);
        setShowApproveMessages("please cancel the trade...");
        setShowDYKmessage("did you know? \n\n trades can be cancelled [after a timeout period] if the other person does not lock/confirm the funds...");
        setShowApproveButton(true);

        try {
            if (!provider || !signer) {
                const newProvider = contractModule.createProvider();
                await newProvider.send("eth_requestAccounts", []);
                const newSigner = contractModule.createSigner(newProvider);
                setProvider(newProvider);
                setSigner(newSigner);
            }
            const chainId = window.ethereum?.networkVersion || "16";
            let p2pContract;
            switch (chainId) {
                case "16": p2pContract = contractModule.createP2PTradeContractCoston_SignY(signer); break;
                case "19": p2pContract = contractModule.createSGBP2PTrade_SignY(signer); break;
                case "14": p2pContract = contractModule.createFLRP2PTrade_SignY(signer); break;
                default: throw new Error(`Unsupported chainId: ${chainId}`);
            }

            if (!p2pInputAgreementCode || !ethers.utils.isHexString(p2pInputAgreementCode)) {
                throw new Error("invalid transaction hash...");
            }
            const storedTrade = localStorage.getItem(`trade_${p2pInputAgreementCode}`);
            if (!storedTrade) throw new Error("no trade found for this transaction hash...");
            const { agreementCode } = JSON.parse(storedTrade);
            if (!ethers.utils.isHexString(agreementCode, 32)) throw new Error("invalid agreement code in storage...");

            const senderAddress = await signer.getAddress();

            const [tradeIds, tradeDetails] = await p2pContract.getUncompletedTrades();
            if (!tradeIds || !tradeDetails || !Array.isArray(tradeIds) || tradeDetails.length === 0) {
                throw new Error("failed to fetch uncompleted trades...");
            }

            const validTrades = tradeDetails.map((trade, index) => ({
                tradeId: tradeIds[index].toNumber(),
                user1: trade.user1,
                user2: trade.user2,
                token1: trade.token1,
                token1Amount: trade.token1Amount.toString(),
                token2: trade.token2,
                token2Amount: trade.token2Amount.toString(),
                user1Deposit: trade.user1Deposit.toString(),
                user2Deposit: trade.user2Deposit.toString(),
                user1Confirmed: trade.user1Confirmed,
                user2Confirmed: trade.user2Confirmed,
                completed: trade.completed,
                agreementCode: trade.agreementCode,
                createdAt: trade.createdAt.toNumber() // Fetch the creation timestamp
            }));
            console.log("Uncompleted trades:", validTrades);

            const userTrades = validTrades.filter(t =>
                t.user1.toLowerCase() === senderAddress.toLowerCase()
            );
            if (userTrades.length === 0) throw new Error("no uncompleted trades found for your address as initiator...");

            const trade = userTrades.find(t => t.agreementCode.toLowerCase() === agreementCode.toLowerCase());
            if (!trade) throw new Error("no uncompleted trade found for this code...");

            if (trade.user2Confirmed) {
                throw new Error("the funds are now locked as the other person already confirmed delivery...");
            }

            // Timeout Logic using Blockchain Timestamp
            //const currentTimestamp = Math.floor(Date.now() / 1000);
            //const threeHoursInSeconds = 3 * 60 * 60;
            //if (currentTimestamp < trade.createdAt + threeHoursInSeconds) {
            //    throw new Error("Cannot cancel yet; 3-hour timeout period has not elapsed...");
            //}

            const tx = await p2pContract.cancelTrade(trade.tradeId, { gasLimit: 3000000 });
            const receipt = await tx.wait();
            setTransactionHash(receipt.transactionHash);
            setTransactionLink(localStorage.getItem('explorerLink') + receipt.transactionHash);
            setShowApproveButton(false);
            setShowP2PTrade(false);
            setP2PInputAgreementCode("");
            setResultMessage("the trade was cancelled successfully and tokens refunded to the user who initiated the trade...");
            window.scrollTo(0, 0);

            const initiationTxHash = Object.keys(localStorage).find(key =>
                key.startsWith("trade_") && JSON.parse(localStorage[key]).agreementCode === agreementCode
            );
            if (initiationTxHash) {
                localStorage.removeItem(initiationTxHash);
                console.log("Removed cancelled trade from localStorage:", initiationTxHash);
            }
        } catch (err) {
            console.error("P2P trade cancellation failed:", err);
            setShowApproveMessages(`error: ${err.message}`);
            setError(`error: ${err.message}`);
        }
    };

    const handleSubmit = async () => {
        console.log("Submit button clicked with payment token:", selectedPaymentToken);
        setShowApproveMessages("please approve the transaction...");
        setShowDYKmessage("did you know? \n\n this transaction includes payment and data submission.");
        setShowApproveButton(true);

        try {
            if (!provider || !signer) {
                const newProvider = contractModule.createProvider();
                setProvider(newProvider);
                await newProvider.send("eth_requestAccounts", []);
                const newSigner = contractModule.createSigner(newProvider);
                setSigner(newSigner);
            } else {
                await provider.send("eth_requestAccounts", []);
            }

            const address = await signer.getAddress();
            const chainId = window.ethereum.networkVersion;
            if (!selectedPaymentToken) throw new Error("Please select a payment token");

            const paymentToken = tokenModule.tokenOptions.find(opt => opt.value === selectedPaymentToken);
            const tokenAddress = paymentToken.address;
            let tokenContract, FactoryMarket, FactoryMarketSignY, FactoryLibrarySignY, FactoryLibrarySignN, fee;

            switch (selectedPaymentToken) {
                case "WSGB":
                    tokenContract = contractModule.createWSGBToken(signer);
                    FactoryMarket = contractModule.FactoryMarketSGB;
                    FactoryMarketSignY = contractModule.createSGBFactoryMarket_SignY(signer);
                    FactoryLibrarySignY = contractModule.createFactoryLibrarySGB_SignY(signer);
                    FactoryLibrarySignN = contractModule.createFactoryLibrarySGB_SignN(provider);
                    fee = payCardFeeWSGB;
                    break;
                case "WFLR":
                    tokenContract = contractModule.createWFLRToken(signer);
                    FactoryMarket = contractModule.FactoryMarketFLR;
                    FactoryMarketSignY = contractModule.createFLRFactoryMarket_SignY(signer);
                    FactoryLibrarySignY = contractModule.createFactoryLibraryFLR_SignY(signer);
                    FactoryLibrarySignN = contractModule.createFactoryLibraryFLR_SignN(provider);
                    fee = payCardFeeWFLR;
                    break;
                case "PERK[C]":
                    tokenContract = contractModule.createPERKTokenCoston(signer);
                    FactoryMarket = contractModule.FactoryMarketCOSTON;
                    FactoryMarketSignY = contractModule.createCOSTONFactoryMarket_SignY(signer);
                    FactoryLibrarySignY = contractModule.createFactoryLibraryCOSTON_SignY(signer);
                    FactoryLibrarySignN = contractModule.createFactoryLibraryCOSTON_SignN(provider);
                    fee = payCardFeePERKCFLR;
                    break;
                case "PERK[S]":
                    tokenContract = contractModule.createPERKTokenSGB(signer);
                    FactoryMarket = contractModule.FactoryMarketSGB;
                    FactoryMarketSignY = contractModule.createSGBFactoryMarket_SignY(signer);
                    FactoryLibrarySignY = contractModule.createFactoryLibrarySGB_SignY(signer);
                    FactoryLibrarySignN = contractModule.createFactoryLibrarySGB_SignN(provider);
                    fee = payCardFeePERKSGB;
                    break;
                case "PERK[F]":
                    tokenContract = contractModule.createPERKTokenFLR(signer);
                    FactoryMarket = contractModule.FactoryMarketFLR;
                    FactoryMarketSignY = contractModule.createFLRFactoryMarket_SignY(signer);
                    FactoryLibrarySignY = contractModule.createFactoryLibraryFLR_SignY(signer);
                    FactoryLibrarySignN = contractModule.createFactoryLibraryFLR_SignN(provider);
                    fee = payCardFeePERKFLR;
                    break;
                default:
                    throw new Error("Unsupported payment token (native tokens not allowed for payment)");
            }

            if (!ethers.utils.isAddress(tokenAddress) || tokenAddress === "0x") {
                throw new Error(`Invalid token address for ${selectedPaymentToken}: ${tokenAddress}`);
            }

            if (!ethers.utils.isAddress(FactoryMarket) || FactoryMarket === "0x") {
                throw new Error(`Invalid factory market address: ${FactoryMarket}`);
            }

            const allowance = await tokenContract.allowance(address, FactoryMarket);
            if (allowance.lt(fee)) {
                setShowApproveMessages("please approve the token transfer...");
                const approveTx = await tokenContract.approve(
                    FactoryMarket,
                    ethers.constants.MaxUint256,
                    { gasLimit: 3000000 }
                );
                await approveTx.wait();
                setShowApproveMessages("now sending payment...");
            }

            const balance = await tokenContract.balanceOf(address);
            if (balance.lt(fee)) throw new Error(`Insufficient ${selectedPaymentToken} balance`);

            const payTx = await FactoryMarketSignY.payERC20forCard(
                tokenAddress,
                fee,
                { gasLimit: 3000000 }
            );
            const payReceipt = await payTx.wait();
            setTransactionHash(`Payment receipt: \n${payReceipt.transactionHash}`);

            const recipient = localStorage.getItem("help-perk_recipient");
            if (!ethers.utils.isAddress(recipient)) {
                throw new Error(`Invalid recipient address: ${recipient}`);
            }

            const titles = `${localStorage.getItem("help-perk_name")}|${localStorage.getItem("help-perk_title")}|${localStorage.getItem("help-perk_email")}`;
            const tokens = `${localStorage.getItem("help-perk_token1")}|${localStorage.getItem("help-perk_token2")}|${localStorage.getItem("help-perk_token3")}`;
            const links = `${localStorage.getItem("help-perk_imagelink")}|${localStorage.getItem("help-perk_website")}`;

            const tx = await FactoryLibrarySignY.setPerk(
                recipient,
                "",
                titles,
                tokens,
                links,
                localStorage.getItem("help-perk_description") || "",
                localStorage.getItem("help-perk_tags") || "",
                "",
                "",
                "",
                { gasLimit: 3000000 }
            );
            const receipt = await tx.wait();
            const perkNumber = await FactoryLibrarySignN.perkNumber();

            setTransactionHash(`Payment: ${payReceipt.transactionHash}\nSubmission: ${receipt.transactionHash}`);
            let linkChain;
            setTransactionLink(localStorage.getItem('explorerLink') + receipt.transactionHash);

            setResultMessage("here is your new perk link:");
            setResult(`https://thedappfactory.com/factory-link/${showGentype}/${linkChain}/${perkNumber}`);
            setShowApproveButton(false);
            setShowstartButton(true);
            setShowPERKsamt(true);
            showPerkLinkSuccess();
        } catch (err) {
            console.error("Transaction failed:", err);
            setShowApproveMessages(`error: ${err.message}`);
            setError(`error: ${err.message}`);
        }
    };

    const handleMix = async () => {
        console.log("Mix function initiated with token:", mixToken1, "amount:", mixAmount, "destination:", mixDestination);
        setShowApproveMessages("please approve the mix transaction...");
        setShowDYKmessage("did you know? \n\n the mixer encrypts your transaction using state of the art obfuscation methods...");
        setShowApproveButton(true);

        try {
            if (!provider || !signer) {
                const newProvider = contractModule.createProvider();
                setProvider(newProvider);
                await newProvider.send("eth_requestAccounts", []);
                const newSigner = contractModule.createSigner(newProvider);
                setSigner(newSigner);
            }

            const chainId = window.ethereum?.networkVersion || (await provider.getNetwork()).chainId;
            const address = await signer.getAddress();

            if (!mixAmount || isNaN(mixAmount) || mixAmount <= 0) throw new Error("Please enter a valid mix amount");
            if (!ethers.utils.isAddress(mixDestination)) throw new Error("Please enter a valid destination address");

            const amount = ethers.utils.parseEther(mixAmount.toString());
            const token = tokenModule.tokenOptions.find(opt => opt.value === mixToken1);
            let mixerContract;

            switch (chainId) {
                case "16":
                    mixerContract = contractModule.createCOSTONFactoryMixer_SignY(signer);
                    if (token.value === "CFLR") {
                        const tx = await mixerContract.mixNative(mixDestination, { value: amount, gasLimit: 3000000 });
                        const receipt = await tx.wait();
                        setTransactionHash(receipt.transactionHash);
                        setTransactionLink(localStorage.getItem('explorerLink') + receipt.transactionHash);
                        window.scrollTo(0, 0);
                    } else if (token.value === "PERK[C]") {
                        const tokenContract = contractModule.createPERKTokenCoston(signer);
                        const allowance = await tokenContract.allowance(address, mixerContract.address);
                        if (allowance.lt(amount)) {
                            setShowApproveMessages("please approve the token transfer...");
                            const approveTx = await tokenContract.approve(mixerContract.address, ethers.constants.MaxUint256, { gasLimit: 3000000 });
                            await approveTx.wait();
                            setShowApproveMessages("now mixing tokens...");
                        }
                        const balance = await tokenContract.balanceOf(address);
                        if (balance.lt(amount)) throw new Error(`Insufficient ${token.value} balance`);
                        const tx = await mixerContract.mixERC20(token.address, amount, mixDestination, { gasLimit: 3000000 });
                        const receipt = await tx.wait();
                        setTransactionHash(receipt.transactionHash);
                        setTransactionLink(localStorage.getItem('explorerLink') + receipt.transactionHash);
                        window.scrollTo(0, 0);
                        const newBalance = await tokenContract.balanceOf(address);
                        setBalance(`you have ${new Intl.NumberFormat("en-US").format(newBalance / 10 ** 18)} PERKS on Coston`);
                    } else {
                        throw new Error(`Unsupported token "${mixToken1}" on Coston`);
                    }
                    break;
                case "19":
                    mixerContract = contractModule.createSGBFactoryMixer_SignY(signer);
                    if (token.value === "SGB") {
                        const tx = await mixerContract.mixNative(mixDestination, { value: amount, gasLimit: 3000000 });
                        const receipt = await tx.wait();
                        setTransactionHash(receipt.transactionHash);
                        setTransactionLink(localStorage.getItem('explorerLink') + receipt.transactionHash);
                        window.scrollTo(0, 0);
                    } else if (token.value === "PERK[S]") {
                        const tokenContract = contractModule.createPERKTokenSGB(signer);
                        const allowance = await tokenContract.allowance(address, mixerContract.address);
                        if (allowance.lt(amount)) {
                            setShowApproveMessages("please approve the token transfer...");
                            const approveTx = await tokenContract.approve(mixerContract.address, ethers.constants.MaxUint256, { gasLimit: 3000000 });
                            await approveTx.wait();
                            setShowApproveMessages("now mixing tokens...");
                        }
                        const balance = await tokenContract.balanceOf(address);
                        if (balance.lt(amount)) throw new Error(`Insufficient ${token.value} balance`);
                        const tx = await mixerContract.mixERC20(token.address, amount, mixDestination, { gasLimit: 3000000 });
                        const receipt = await tx.wait();
                        setTransactionHash(receipt.transactionHash);
                        setTransactionLink(localStorage.getItem('explorerLink') + receipt.transactionHash);
                        window.scrollTo(0, 0);
                        const newBalance = await tokenContract.balanceOf(address);
                        setBalance(`you have ${new Intl.NumberFormat("en-US").format(newBalance / 10 ** 18)} PERKS on Songbird`);
                    } else if (token.value === "WSGB") {
                        const tokenContract = contractModule.createWSGBToken(signer);
                        const allowance = await tokenContract.allowance(address, mixerContract.address);
                        if (allowance.lt(amount)) {
                            setShowApproveMessages("please approve the token transfer...");
                            const approveTx = await tokenContract.approve(mixerContract.address, ethers.constants.MaxUint256, { gasLimit: 3000000 });
                            await approveTx.wait();
                            setShowApproveMessages("now mixing tokens...");
                        }
                        const balance = await tokenContract.balanceOf(address);
                        if (balance.lt(amount)) throw new Error(`Insufficient ${token.value} balance`);
                        const tx = await mixerContract.mixERC20(token.address, amount, mixDestination, { gasLimit: 3000000 });
                        const receipt = await tx.wait();
                        setTransactionHash(receipt.transactionHash);
                        setTransactionLink(localStorage.getItem('explorerLink') + receipt.transactionHash);
                        window.scrollTo(0, 0);
                    } else {
                        throw new Error(`Unsupported token "${mixToken1}" on Songbird`);
                    }
                    break;
                case "14":
                    mixerContract = contractModule.createFLRFactoryMixer_SignY(signer);
                    if (token.value === "FLR") {
                        const tx = await mixerContract.mixNative(mixDestination, { value: amount, gasLimit: 3000000 });
                        const receipt = await tx.wait();
                        setTransactionHash(receipt.transactionHash);
                        setTransactionLink(localStorage.getItem('explorerLink') + receipt.transactionHash);
                        window.scrollTo(0, 0);
                    } else if (token.value === "PERK[F]") {
                        const tokenContract = contractModule.createPERKTokenFLR(signer);
                        const allowance = await tokenContract.allowance(address, mixerContract.address);
                        if (allowance.lt(amount)) {
                            setShowApproveMessages("please approve the token transfer...");
                            const approveTx = await tokenContract.approve(mixerContract.address, ethers.constants.MaxUint256, { gasLimit: 3000000 });
                            await approveTx.wait();
                            setShowApproveMessages("now mixing tokens...");
                        }
                        const balance = await tokenContract.balanceOf(address);
                        if (balance.lt(amount)) throw new Error(`Insufficient ${token.value} balance`);
                        const tx = await mixerContract.mixERC20(token.address, amount, mixDestination, { gasLimit: 3000000 });
                        const receipt = await tx.wait();
                        setTransactionHash(receipt.transactionHash);
                        setTransactionLink(localStorage.getItem('explorerLink') + receipt.transactionHash);
                        window.scrollTo(0, 0);
                        const newBalance = await tokenContract.balanceOf(address);
                        setBalance(`you have ${new Intl.NumberFormat("en-US").format(newBalance / 10 ** 18)} PERKS on Flare`);
                    } else if (token.value === "WFLR") {
                        const tokenContract = contractModule.createWFLRToken(signer);
                        const allowance = await tokenContract.allowance(address, mixerContract.address);
                        if (allowance.lt(amount)) {
                            setShowApproveMessages("please approve the token transfer...");
                            const approveTx = await tokenContract.approve(mixerContract.address, ethers.constants.MaxUint256, { gasLimit: 3000000 });
                            await approveTx.wait();
                            setShowApproveMessages("now mixing tokens...");
                        }
                        const balance = await tokenContract.balanceOf(address);
                        if (balance.lt(amount)) throw new Error(`Insufficient ${token.value} balance`);
                        const tx = await mixerContract.mixERC20(token.address, amount, mixDestination, { gasLimit: 3000000 });
                        const receipt = await tx.wait();
                        setTransactionHash(receipt.transactionHash);
                        setTransactionLink(localStorage.getItem('explorerLink') + receipt.transactionHash);
                        window.scrollTo(0, 0);
                    } else {
                        throw new Error(`Unsupported token "${mixToken1}" on Flare`);
                    }
                    break;
                default:
                    throw new Error("Unsupported network");
            }

            setResultMessage("here is your mix receipt:");
            setShowApproveButton(false);
            setShowstartButton(true);
            setShowPERKsamt(true);
            setShowMixer(false);
            setMixAmount("");
            setMixDestination("");
            setMixToken1("");
            setError("");
        } catch (err) {
            console.error("Mix failed:", err);
            setShowApproveMessages(`error: ${err.message}`);
            setError(`error: ${err.message}`);
        }
    };

    const handleClaimToken1 = (e) => {
        console.log("Claim token selected:", e.value);
        tokenModule.CheckTokenNetwork(e.value);
        setClaimToken1(e.value);
    };

    const handleClaimToggle = () => {
        if (claimButtonText === "mix") {
            setclaimButtonText("claim");
            setShowClaimSelector(false);
            setShowClaimMode(false);
            setShowMixAmount(true);
            setShowMixSelector(true);
            setShowMixDestination(true);
            setShowMixSubmit(true);
        } else if (claimButtonText === "claim") {
            setclaimButtonText("mix");
            setShowClaimSelector(true);
            setShowClaimMode(true);
            setShowMixAmount(false);
            setShowMixSelector(false);
            setShowMixDestination(false);
            setShowMixSubmit(false);
        }
    };

    const handleP2PToggle = () => {
        if (p2pButtonText === "finalize") {
            setp2pButtonText("deposit");
            setshowP2PToken1(false);
            setshowP2PToken2(false);
            setshowP2PUser2(false);
            setshowP2PTradeButton(false);
            setshowP2PUncompleted(true);
            setshowP2PFinalButton(true);
        } else if (p2pButtonText === "deposit") {
            setp2pButtonText("finalize");
            setshowP2PUncompleted(false);
            setshowP2PFinalButton(false);
            setshowP2PToken1(true);
            setshowP2PToken2(true);
            setshowP2PUser2(true);
            setshowP2PTradeButton(true);
        }
    };

    const finishClaimCode = () => {
        setShowClaimSelector(false);
        setShowClaimMode(false);
        setShowApproveButton(false);
        setShowstartButton(true);
        setShowPERKsamt(true);
        setShowMixer(false);
        setMixAmount("");
        setMixDestination("");
        setMixToken1("");
        setError("");
        setResultMessage("here is your claim receipt:");
    };

    const handleClaimSubmit = async () => {
        console.log("Claim function initiated with token:", claimToken1);
        setShowApproveMessages("checking your claimable balance...");
        setShowDYKmessage("did you know? \n\n your funds are encrypted using state of the art obfuscation methods!");
        setShowApproveButton(true);

        try {
            if (!provider || !signer) {
                const newProvider = contractModule.createProvider();
                await newProvider.send("eth_requestAccounts", []);
                const newSigner = contractModule.createSigner(newProvider);
                setProvider(newProvider);
                setSigner(newSigner);
            }

            const address = await signer.getAddress();
            const chainId = window.ethereum?.networkVersion || (await provider.getNetwork()).chainId;
            const token = tokenModule.tokenOptions.find(opt => opt.value === claimToken1);
            let mixerContract, claimable;

            switch (chainId) {
                case "16":
                    mixerContract = contractModule.createCOSTONFactoryMixer_SignY(signer);
                    if (token.value === "CFLR") {
                        claimable = await mixerContract.getNativeBalance(address);
                        if (claimable.eq(0)) {
                            setShowApproveMessages("this address has no CFLR to claim...");
                        } else {
                            setShowApproveMessages("please approve the 'claim' transaction...");
                            const tx = await mixerContract.claimNativeRandomized(0, 30, { gasLimit: 3000000 });
                            const receipt = await tx.wait();
                            setTransactionHash(receipt.transactionHash);
                            setTransactionLink(localStorage.getItem('explorerLink') + receipt.transactionHash);
                            window.scrollTo(0, 0);
                            finishClaimCode();
                        }
                    } else if (token.value === "PERK[C]") {
                        claimable = await mixerContract.getERC20Balance(address, token.address);
                        if (claimable.eq(0)) {
                            setShowApproveMessages("this address has no PERKS to claim on Coston...");
                        } else {
                            setShowApproveMessages("please approve the 'claim' transaction...");
                            const tx = await mixerContract.claimERC20Randomized(token.address, 0, 20, { gasLimit: 3000000 });
                            const receipt = await tx.wait();
                            setTransactionHash(receipt.transactionHash);
                            setTransactionLink(localStorage.getItem('explorerLink') + receipt.transactionHash);
                            window.scrollTo(0, 0);
                            const newBalance = await contractModule.createPERKTokenCoston(signer).balanceOf(address);
                            setBalance(`you have ${new Intl.NumberFormat("en-US").format(newBalance / 10 ** 18)} PERKS on Coston`);
                            finishClaimCode();
                        }
                    } else {
                        throw new Error(`Unsupported token "${claimToken1}" on Coston`);
                    }
                    break;
                case "19":
                    mixerContract = contractModule.createSGBFactoryMixer_SignY(signer);
                    if (token.value === "SGB") {
                        claimable = await mixerContract.getNativeBalance(address);
                        if (claimable.eq(0)) {
                            setShowApproveMessages("this address has no SGB to claim...");
                        } else {
                            setShowApproveMessages("please approve the 'claim' transaction...");
                            const tx = await mixerContract.claimNativeRandomized(0, 30, { gasLimit: 3000000 });
                            const receipt = await tx.wait();
                            setTransactionHash(receipt.transactionHash);
                            setTransactionLink(localStorage.getItem('explorerLink') + receipt.transactionHash);
                            window.scrollTo(0, 0);
                            finishClaimCode();
                        }
                    } else if (token.value === "PERK[S]") {
                        claimable = await mixerContract.getERC20Balance(address, token.address);
                        if (claimable.eq(0)) {
                            setShowApproveMessages("this address has no PERKS to claim on Songbird...");
                        } else {
                            setShowApproveMessages("please approve the 'claim' transaction...");
                            const tx = await mixerContract.claimERC20Randomized(token.address, 0, 30, { gasLimit: 3000000 });
                            const receipt = await tx.wait();
                            setTransactionHash(receipt.transactionHash);
                            setTransactionLink(localStorage.getItem('explorerLink') + receipt.transactionHash);
                            window.scrollTo(0, 0);
                            const newBalance = await contractModule.createPERKTokenSGB(signer).balanceOf(address);
                            setBalance(`you have ${new Intl.NumberFormat("en-US").format(newBalance / 10 ** 18)} PERKS on Songbird`);
                            finishClaimCode();
                        }
                    } else if (token.value === "WSGB") {
                        claimable = await mixerContract.getERC20Balance(address, token.address);
                        if (claimable.eq(0)) {
                            setShowApproveMessages("this address has no WSGB to claim...");
                        } else {
                            setShowApproveMessages("please approve the 'claim' transaction...");
                            const tx = await mixerContract.claimERC20Randomized(token.address, 0, 30, { gasLimit: 3000000 });
                            const receipt = await tx.wait();
                            setTransactionHash(receipt.transactionHash);
                            setTransactionLink(localStorage.getItem('explorerLink') + receipt.transactionHash);
                            window.scrollTo(0, 0);
                            finishClaimCode();
                        }
                    } else {
                        throw new Error(`Unsupported token "${claimToken1}" on Songbird`);
                    }
                    break;
                case "14":
                    mixerContract = contractModule.createFLRFactoryMixer_SignY(signer);
                    if (token.value === "FLR") {
                        claimable = await mixerContract.getNativeBalance(address);
                        if (claimable.eq(0)) {
                            setShowApproveMessages("this address has no FLR to claim...");
                        } else {
                            setShowApproveMessages("please approve the 'claim' transaction...");
                            const tx = await mixerContract.claimNativeRandomized(0, 30, { gasLimit: 3000000 });
                            const receipt = await tx.wait();
                            setTransactionHash(receipt.transactionHash);
                            setTransactionLink(localStorage.getItem('explorerLink') + receipt.transactionHash);
                            window.scrollTo(0, 0);
                            finishClaimCode();
                        }
                    } else if (token.value === "PERK[F]") {
                        claimable = await mixerContract.getERC20Balance(address, token.address);
                        if (claimable.eq(0)) {
                            setShowApproveMessages("this address has no PERKS to claim on Flare...");
                        } else {
                            setShowApproveMessages("please approve the 'claim' transaction...");
                            const tx = await mixerContract.claimERC20Randomized(token.address, 0, 30, { gasLimit: 3000000 });
                            const receipt = await tx.wait();
                            setTransactionHash(receipt.transactionHash);
                            setTransactionLink(localStorage.getItem('explorerLink') + receipt.transactionHash);
                            window.scrollTo(0, 0);
                            const newBalance = await contractModule.createPERKTokenFLR(signer).balanceOf(address);
                            setBalance(`you have ${new Intl.NumberFormat("en-US").format(newBalance / 10 ** 18)} PERKS on Flare`);
                            finishClaimCode();
                        }
                    } else if (token.value === "WFLR") {
                        claimable = await mixerContract.getERC20Balance(address, token.address);
                        if (claimable.eq(0)) {
                            setShowApproveMessages("this address has no WFLR to claim...");
                        } else {
                            setShowApproveMessages("please approve the 'claim' transaction...");
                            const tx = await mixerContract.claimERC20Randomized(token.address, 0, 30, { gasLimit: 3000000 });
                            const receipt = await tx.wait();
                            setTransactionHash(receipt.transactionHash);
                            setTransactionLink(localStorage.getItem('explorerLink') + receipt.transactionHash);
                            window.scrollTo(0, 0);
                            finishClaimCode();
                        }
                    } else {
                        throw new Error(`Unsupported token "${claimToken1}" on Flare`);
                    }
                    break;
                default:
                    throw new Error("Unsupported network");
            }
        } catch (err) {
            console.error("Claim failed:", err);
            setShowApproveMessages(`error: ${err.message}`);
            setError(`error: ${err.message}`);
        }
    };

    const handleMixToken1 = (e) => {
        console.log("Mix token selected:", e.value);
        tokenModule.CheckTokenNetwork(e.value);
        setMixToken1(e.value);
    };

    const handleMixAmountChange = (e) => {
        setMixAmount(e.target.value);
    };

    const handleMixDestinationChange = (e) => {
        setMixDestination(e.target.value);
    };

    const handleInputChange = (field, value) => {
        setCardHelp(prev => ({
            ...prev,
            [field]: value
        }));
    };

    // Function to handle cancellation
    const handleDisclaimerCancel = () => {
        setShowDisclaimer(false); // Close the P2P disclaimer modal
        setShowMixerDisclaimer(false); // Close the mixer disclaimer modal
        setStartButtonText("start");
        setStartButtonColor("transparent");
        setShowMenu(false);
        setShowMenuOptions(false);
        setShowGenerator(false);
        setShowMixer(false);
        setShowP2PTrade(false);
        setP2Ptoken1("");
        setP2Ptoken2("");
        setP2PAmount1("");
        setP2PAmount2("");
        setp2pUser2("");
        setP2PAgreementCode("");
        setshowP2PSelectedTrade(null);
        setShowMixAmount(false);
        setShowMixSelector(false);
        setShowMixDestination(false);
        setShowMixSubmit(false);
        setShowGenSelector(false);
        setShowGentype("");
        setSelectedOption("");
        setShowGenemail(false);
        setShowGenname(false);
        setShowGentitle(false);
        setShowGendescription(false);
        setShowGenwebsite(false);
        setShowGenrecipient(false);
        setShowGentoken1(false);
        setShowGentoken2(false);
        setShowGentoken3(false);
        setShowGentags(false);
        setShowGenimage(false);
        setShowPreviewButton(false);
        setShowGensubmit(false);
        setShowGensuccess(false);
        setShowPaymentOptions(false);
        setShowPERKsamt(true);
        setShowComingSoon(false);
        setTransactionHash("");
        setTransactionLink("");
        setResultMessage("");
        setResult("");
        setError("");
        setSelectedToken1("");
        setSelectedToken2("");
        setSelectedToken3("");
        setMixToken1("");
        setSelectedPaymentToken("");
        setShowCardButton(false);
        window.scrollTo(0, 0);
    };

    return (
        <feesModule.FeesProvider>
            <div className="App">
                {/* P2P Disclaimer Modal */}
                <DisclaimerModal
                    isOpen={showDisclaimer}
                    onAgree={handleDisclaimerAgree}
                    onCancel={() => handleDisclaimerCancel("trade")}
                    title="Terms of Use"
                    line1="P2P trading is not without risk, including potential loss of funds [fiat trades]."

                    line2="the app works like this: user will initiate a trade agreement (deposit tokens), then send the transaction hash to a 
                            second user. User2 will then finalize the transaction with the transaction hash and the tokens will be 
                            disbursed to both users automatically (minus a small fee from each user for equity). There is a set lock period, after which User1 can
                            cancel the transaction for a full refund if User2 has not finalized the trade."
                            
                    line3="Both users need to finalize the trade when fiat is involved, User2 confirmation locks the tokens, User1 confirmation 
                            releases the tokens to User2 [ensure you trust the counterparty or use escrow services for fiat trades. the dApp factory 
                            has done everything possible to mitigate risk]. Funds can not be recovered if locked, lost, or stolen."

                    line4="the dApp factory is also not responsible for any misuse, misrepresentation, legality in different
                    jurisdictions, criminal use, and basically everything you can think of... if companies and manufacturers are not legally liable for
                    criminals using drugs, guns, fiat, etc., the dApp factory is not responsible for how you use this code."
                />

                {/* Mixer Disclaimer Modal */}
                <DisclaimerModal
                    isOpen={showMixerDisclaimer}
                    onAgree={handleMixerDisclaimerAgree}
                    onCancel={() => handleDisclaimerCancel("mixer")}
                    title="Terms of Use"
                    line1="Crypto mixers are not without risk."

                    line2="Ensure you understand the legal implications in your jurisdiction."

                    line3="the app works like this: you initiate a mix, the destination wallet address is encrypted using rolling, dual, one-time use
                            encryption keys, the funds are split into random amounts and sent through multiple forwarding contracts, arriving at a pool 
                            where you then collect the funds [which are sent to the destination wallet in radomly split transactions]."
                    line4="the dApp factory is also not responsible for any misuse, misrepresentation, legality in different
                    jurisdictions, criminal use, and basically everything you can think of... if companies and manufacturers are not legally liable for
                    criminals using drugs, guns, fiat, etc., the dApp factory is not responsible for how you use this code."              />

                <div className='chainName'>{chainNameCard}</div>

                {showDarkMode && (
                    <div id="darkMode"
                        className="btn-on"
                        onClick={handleToggleDarkMode}
                        style={{ cursor: "pointer", pointerEvents: "auto", zIndex: 1000 }}
                    >
                        dark
                    </div>
                )}

                {error && <div id="errorMessage">{error}</div>}

                <div className="start-block">
                    <div id="yourPERKs" className={`fade-animation ${showPERKsamt ? 'show' : 'hide'}`}>
                        {balance}
                    </div>

                    <div id="menu" className={`slide-up-menu ${showMenu ? 'show' : 'hide'}`}>
                        <div id="menu-options" className={`slide-animation ${showMenuOptions ? 'show' : 'hide'}`}>
                            |{" "}
                            <button
                                id="menu-search"
                                className="menu-button"
                                onClick={comingSoon}
                            >
                                swaps
                            </button>{" "}
                            |{" "}
                            <button
                                id="menu-mixer"
                                className="menu-button"
                                onClick={clickMixer}
                            >
                                mixer
                            </button>{" "}
                            |{" "}
                            <button
                                id="menu-p2p"
                                className="menu-button"
                                onClick={clickP2P}
                            >
                                p2p
                            </button>{" "}
                            |{" "}
                            <button
                                id="menu-generator"
                                className="menu-button"
                                onClick={clickGenerator}
                            >
                                cards
                            </button>{" "}
                            |{" "}
                            <a
                                id="menu-factory"
                                href="/factory-tokens"
                                className="menu-link"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open("/factory-tokens", "_self");
                                }}
                            >
                                perks
                            </a>{" "}
                            |
                        </div>

                        <div className={`slide-up-menu ${showComingSoon ? 'show' : 'hide'}`}>
                            <div id="coming-soon">this feature is being developed</div>
                        </div>
                    </div>

                    <br></br>
                    <div className="font-title" style={{ whiteSpace: "pre" }}>
                        dApp <div id="flare-f">f</div>actory
                    </div>
                    <br></br>
                    <div className={`slide-animation ${showWelcome ? 'show' : 'hide'}`}>
                        <div id="welcomeMessage">{showAIMessage}</div>
                    </div>

                    {showstartButton && (
                        <div>
                            <div
                                id="start"
                                style={{
                                    backgroundColor: startButtonColor,
                                    cursor: "pointer",
                                    pointerEvents: "auto",
                                    zIndex: 2000,
                                    position: "relative",
                                }}
                                onClick={StartFunction}
                            >
                                {startButtonText}
                            </div>
                        </div>
                    )}
                </div>

                {showGenerator && (
                    <div id="generator" className={`slide-animation ${showGenerator ? 'show' : 'hide'}`}>
                        {showGenSelector && (
                            <Select
                                id=""
                                className="react-select"
                                options={options}
                                onChange={handleTypeSelect}
                                value={options.find((option) => option.value === selectedOption)}
                                onMenuOpen={handleMenuOpen}
                                placeholder="...select a type"
                            />
                        )}

                        {showPaymentOptions && (
                            <div>
                                pay foe your card with:<br />
                                <Select
                                    id="gen-payment-token"
                                    className="react-select"
                                    options={tokenModule.tokenOptions.filter(opt => opt.address !== "0x0000000000000000000000000000000000000000" && opt.address !== "0x1000000000000000000000000000000000000000")}
                                    onChange={handlePaymentTokenSelect}
                                    value={tokenModule.tokenOptions.find((option) => option.value === selectedPaymentToken)}
                                    onMenuOpen={handleMenuOpen}
                                    placeholder="...select token"
                                />
                            </div>
                        )}
                        <form id="gen-form">
                            <div style={{ display: "none" }} className={showGentype ? "show" : "hide"}>
                                <input
                                    type="text"
                                    id="perktype"
                                    name="perktype"
                                    value={showGentype}
                                    onChange={() => { }}
                                />
                            </div>

                            {showGenemail && (
                                <div id="gen-email" >
                                    your email address:<br />
                                    <input
                                        type="text"
                                        name="gen-email"
                                        onChange={(e) => {
                                            handleInputChange("email", e.target.value);
                                            localStorage.setItem("help-perk_email", e.target.value);
                                        }}
                                    />
                                </div>
                            )}

                            {showGenname && (
                                <div id="gen-namejes" >
                                    name your perk:<br />
                                    <input
                                        type="text"
                                        placeholder=""
                                        name="gen-name"
                                        onChange={(e) => {
                                            handleInputChange("name", e.target.value);
                                            localStorage.setItem("help-perk_name", e.target.value);
                                        }}
                                    />
                                </div>
                            )}

                            {showGentitle && (
                                <div id="gen-title" >
                                    give your perk a 'title':<br />
                                    <input
                                        type="text"
                                        placeholder="required"
                                        name="gen-title"
                                        onChange={(e) => {
                                            handleInputChange("title", e.target.value);
                                            localStorage.setItem("help-perk_title", e.target.value);
                                        }}
                                    />
                                </div>
                            )}

                            {showGendescription && (
                                <div id="gen-description" >
                                    tell your story:<br />
                                    <textarea
                                        type="text"
                                        placeholder="1000 characters max"
                                        name="gen-description"
                                        onChange={(e) => {
                                            handleInputChange("description", e.target.value);
                                            localStorage.setItem("help-perk_description", e.target.value);
                                        }}
                                    />
                                </div>
                            )}

                            {showGenwebsite && (
                                <div id="gen-website" >
                                    website to display:<br />
                                    <input
                                        type="text"
                                        placeholder=""
                                        name="gen-website"
                                        onChange={(e) => {
                                            handleInputChange("website", e.target.value);
                                            localStorage.setItem("help-perk_website", e.target.value);
                                        }}
                                    />
                                </div>
                            )}

                            {showGenrecipient && (
                                <div id="gen-recipient" >
                                    your wallet address<br />
                                    <input
                                        type="text"
                                        placeholder="required format 0x1234..."
                                        name="gen-recipient"
                                        onChange={(e) => {
                                            handleInputChange("recipient", e.target.value);
                                            localStorage.setItem("help-perk_recipient", e.target.value);
                                        }}
                                    />
                                </div>
                            )}

                            {showGentoken1 && (
                                <div >
                                    token [1st button]:<br />
                                    <Select
                                        id="gen-token1"
                                        className="react-select"
                                        options={tokenModule.tokenOptions}
                                        onChange={handleTokenSelect1}
                                        value={tokenModule.tokenOptions.find((option) => option.value === selectedToken1)}
                                        onMenuOpen={handleMenuOpen}
                                        placeholder="...select a token"
                                    />
                                </div>
                            )}

                            {showGentoken2 && (
                                <div >
                                    token [2nd button]:<br />
                                    <Select
                                        id="gen-token2"
                                        className="react-select"
                                        options={tokenModule.tokenOptions}
                                        onChange={handleTokenSelect2}
                                        value={tokenModule.tokenOptions.find((option) => option.value === selectedToken2)}
                                        onMenuOpen={handleMenuOpen}
                                        placeholder="...select a token"
                                    />
                                </div>
                            )}

                            {showGentoken3 && (
                                <div >
                                    token [3rd button]:<br />
                                    <Select
                                        id="gen-token3"
                                        className="react-select"
                                        options={tokenModule.tokenOptions}
                                        onChange={handleTokenSelect3}
                                        value={tokenModule.tokenOptions.find((option) => option.value === selectedToken3)}
                                        onMenuOpen={handleMenuOpen}
                                        placeholder="...select a token"
                                    />
                                </div>
                            )}

                            {showGentags && (
                                <div id="gen-tags" >
                                    keywords [for search]:<br />
                                    <input
                                        type="text"
                                        placeholder=""
                                        name="gen-tags"
                                        onChange={(e) => {
                                            handleInputChange("tags", e.target.value);
                                            localStorage.setItem("help-perk_tags", e.target.value);
                                        }}
                                    />
                                </div>
                            )}

                            {showGenimage && (
                                <div id="gen-image" >
                                    photo URL [link]:<br />
                                    <input
                                        type="text"
                                        placeholder=""
                                        name="gen-image"
                                        onChange={(e) => {
                                            handleInputChange("imageLink", e.target.value);
                                            localStorage.setItem("help-perk_imagelink", e.target.value);
                                        }}
                                    />
                                </div>
                            )}

                            {showPreviewButton && (
                                <div className="slide-upMenu">
                                    <button
                                        id="gen-perkVerify"
                                        className="form-action-button"
                                        type="button"
                                        onClick={clickPreview}
                                    >
                                        preview
                                    </button>
                                </div>
                            )}

                            <br />
                            {showGensubmit && selectedPaymentToken && (
                                <div className="slide-submitButton">
                                    <button
                                        id="gen-perkSubmit"
                                        className="form-action-button"
                                        type="button"
                                        onClick={handleSubmit}
                                    >
                                        submit
                                    </button>
                                    <br />
                                    <br />
                                </div>
                            )}
                        </form>
                    </div>
                )}

                <div id="mixer" className={`slide-animation ${showMixer ? 'show' : 'hide'}`}>
                    <button className="claim-toggle-button" onClick={handleClaimToggle}>
                        {claimButtonText}
                    </button>
                    <br />
                    <br />
                    {showMixAmount && (
                        <div id="mix-amount" >
                            i want to mix:<br />
                            <input
                                type="text"
                                name="mix-amount"
                                placeholder="how many tokens?"
                                value={mixAmount}
                                onChange={handleMixAmountChange}
                            />
                        </div>
                    )}
                    {showMixSelector && (
                        <div className="slide-upMixer">
                            of the following token:<br />
                            <Select
                                id="mix-token1"
                                className="react-select"
                                options={tokenModule.tokenOptions.filter(opt => opt.address !== "0x1000000000000000000000000000000000000000")}
                                onChange={handleMixToken1}
                                value={tokenModule.tokenOptions.find((option) => option.value === mixToken1)}
                                onMenuOpen={handleMenuOpen}
                                placeholder="...select a token"
                            />
                        </div>
                    )}
                    {showMixDestination && (
                        <div id="mix-destination" >
                            sent to:<br />
                            <input
                                type="text"
                                name="mix-destination"
                                placeholder="paste destination wallet here"
                                value={mixDestination}
                                onChange={handleMixDestinationChange}
                            />
                        </div>
                    )}
                    {showMixSubmit && (
                        <div className="">
                            <button
                                id="mix-Submit"
                                className="form-action-button"
                                onClick={handleMix}
                            >
                                mix
                            </button>
                        </div>
                    )}
                    {showClaimSelector && (
                        <div>
                            I want to claim:<br />
                            <Select
                                id="claim-token1"
                                className="react-select"
                                options={tokenModule.tokenOptions}
                                onChange={handleClaimToken1}
                                value={tokenModule.tokenOptions.find((option) => option.value === claimToken1)}
                                onMenuOpen={handleMenuOpen}
                                placeholder="...select a token"
                            />
                        </div>
                    )}
                    {showClaimMode && (
                        <div className={`slide-animation ${showMixer ? 'show' : 'hide'}`}>
                            <button className="form-action-button" onClick={handleClaimSubmit}>
                                claim
                            </button>
                        </div>
                    )}
                </div>

                <div id="p2p-trade" className={`slide-animation ${showP2PTrade ? 'show' : 'hide'}`}>
                    <button className="claim-toggle-button" onClick={handleP2PToggle}>
                        {p2pButtonText}
                    </button>
                    <br />
                    <br />
                    {showP2PToken1 && (
                        <div id="p2p-token1">
                            I want to trade<br />
                            <input
                                type="text"
                                name="p2p-amount1"
                                placeholder="how many tokens?"
                                value={p2pAmount1}
                                onChange={(e) => setP2PAmount1(e.target.value)}
                            />
                            of this token...<br />
                            <Select
                                id="p2p-token1-select"
                                className="react-select"
                                options={tokenModule.tokenOptions.filter(opt => opt.address !== "0x1000000000000000000000000000000000000000")}
                                onChange={handleP2Ptoken1}
                                value={tokenModule.tokenOptions.find((option) => option.value === p2ptoken1)}
                                onMenuOpen={handleMenuOpen}
                                placeholder="...select a token"
                            />
                        </div>
                    )}
                    {showP2PToken2 && (
                        <div id="p2p-token2">
                            for this many<br />
                            <input
                                type="text"
                                name="p2p-amount2"
                                placeholder="how many tokens?"
                                value={p2pAmount2}
                                onChange={(e) => setP2PAmount2(e.target.value)}
                            />
                            of this token...<br />
                            <Select
                                id="p2p-token2-select"
                                className="react-select"
                                options={tokenModule.tokenOptions}
                                onChange={handleP2Ptoken2}
                                value={tokenModule.tokenOptions.find((option) => option.value === p2ptoken2)}
                                onMenuOpen={handleMenuOpen}
                                placeholder="...select a token"
                            />
                        </div>
                    )}
                    {showP2PUser2 && (
                        <div id="p2p-user2">
                            with this person:<br />
                            <input
                                type="text"
                                name="p2p-user2"
                                placeholder="paste destination wallet here"
                                value={p2pUser2}
                                onChange={(e) => setp2pUser2(e.target.value)}
                            />
                        </div>
                    )}
                    {showP2PTradeButton && (
                        <div>
                            <button
                                id="p2p-initiate"
                                className="form-action-button"
                                onClick={handleInitiateP2PTrade}
                            >
                                trade
                            </button>
                        </div>
                    )}
                    {showP2PUncompleted && (
                        <div id="p2p-uncompleted">
                            [paste your agreement code]<br />
                            <input
                                type="text"
                                name="p2p-agreement-code"
                                placeholder="e.g., 0x1234abcd..."
                                value={p2pInputAgreementCode}
                                onChange={(e) => setP2PInputAgreementCode(e.target.value)}
                            />
                        </div>
                    )}
                    {showP2PFinalButton && (
                        <div>
                            <button
                                id="p2p-finalize"
                                className="form-action-button"
                                onClick={handleCompleteP2PTrade}
                            >
                                finalize
                            </button>
                            <br />
                            <button
                                id="p2p-cancel"
                                className="form-action-button"
                                onClick={handleCancelP2PTrade}
                                style={{ marginTop: "10px" }} // Add some spacing
                            >
                                cancel
                            </button>
                        </div>
                    )}
                </div>


                <div id="form-success">
                    <div>{resultMessage}</div>
                    <br />
                    <a href={result} target="_blank" rel="noreferrer">
                        {result}
                    </a>
                    <br />
                    <a href={transactionLink} className="transaction-link" target="_blank" rel="noreferrer">
                        {transactionHash}
                    </a>
                </div>

                {showApproveButton && (
                    <div className={`back-Mainoverlay ${fadingOutApprove ? 'fade-out' : 'fade-in'}`}>
                        <div id="approval-objects">
                            <div className="approveMessage">{showApproveMessages}</div>
                            <br />
                            <div
                                id="goBackbutton"
                                className="payButtons"
                                onClick={() => {
                                    console.log("Go back clicked");
                                    setFadingOutApprove(true);
                                    setTimeout(() => {
                                        setShowApproveButton(false);
                                        setFadingOutApprove(false);
                                        setShowstartButton(true);
                                        setShowPERKsamt(true);
                                    }, 500);
                                }}
                            >
                                GO BACK
                            </div>
                            <br /><br /><br />
                            <div className="DYKmessage">{showDYKmessage}</div>
                        </div>
                    </div>
                )}
            </div>
        </feesModule.FeesProvider>
    );
}

export default function App() {
    const [showCardButton, setShowCardButton] = useState(false);
    const onModalClose = useRef(() => { });
    const [cardHelp, setCardHelp] = useState({
        title: 'title of your card appears here \n',
        name: 'card name',
        recipient: 'address where the crypto is sent will display here',
        description: 'the description details you enter will display here \n[max 1000 characters]',
        imageLink: 'https://storage.googleapis.com/ff-bucket-files/Files/animated-PerkPromo.gif',
        imageSize: 'contain',
        website: 'website you provide will appear here \n',
        contentLink: '',
        token1: 'WSGB',
        token2: 'PERK',
        token3: 'WFLR',
        tags: '',
    });

    return (
        <Perk
            showCardButton={showCardButton}
            setShowCardButton={setShowCardButton}
            onModalClose={onModalClose}
            cardHelp={cardHelp}
            setCardHelp={setCardHelp}
        />
    );
}