import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import * as contractModule from "./factoryvariables-contracts.js";
import * as networkModule from "./factoryvariables-networks.js";
import * as tokenModule from "./factoryvariables-tokens.js";
import * as feesModule from "./factoryvariables-fees.js";
import "./factory.css";

function FactoryCard({ cardHelp, setCardHelp, isPreview = false }) {
    const [receiptMessage, setreceiptMessage] = useState("");
    const [state, setState] = useState(false);
    const [loading, setLoading] = useState(true);
    const [connected, setConnected] = useState(false);
    const [currentAddress, setCurrentAddress] = useState('');
    const [chainId, setChainId] = useState(null);
    const [chainNameCard, setChainNameCard] = useState(localStorage.getItem('chainNameCard'));
    const [showApproveButton, setShowApproveButton] = useState(false);
    const [showApproveMessage, setShowApproveMessages] = useState('');
    const [showDYKmessage, setShowDYKmessage] = useState("");
    const [transactionHash, setTransactionHash] = useState('');
    const [transactionLink, setTransactionLink] = useState('');

    const [cardData, setCardData] = useState({
        title: '',
        name: '',
        recipient: '',
        description: '',
        imageLink: 'https://storage.googleapis.com/ff-bucket-files/Files/animated-PerkPromo.gif',
        imageSize: 'contain',
        website: '',
        contentLink: '',
        token1: 'WSGB',
        token2: 'PERK',
        token3: 'WFLR',
        tags: '',
    });

    const { pathname } = window.location;
    const [, , perkType, perkNetwork, perkNumber] = pathname.split('/');

    const CONTRACT_ADDRESSES = {
        coston: contractModule.FactoryLibraryCOSTON,
        songbird: contractModule.FactoryLibrarySGB,
        flare: contractModule.FactoryLibraryFLR,
    };

    const ABI = [
        'function PerkCardData(uint256 perkNumber) view returns (uint256, string, string, string, string, string, string, string, string, string, string)'
    ];

    useEffect(() => {
        if (isPreview) {
            setCardData(cardHelp);
            setState(true);
            setLoading(false);
            setreceiptMessage("");
        } else {
            const init = async () => {
                setShowApproveMessages('');
                setShowDYKmessage('');
                setreceiptMessage("");

                if (!window.ethereum) {
                    setLoading(false);
                    setState(false);
                    return;
                }

                const provider = new ethers.providers.Web3Provider(window.ethereum);

                try {
                    await provider.send('eth_requestAccounts', []);
                    const signer = provider.getSigner();
                    const address = await signer.getAddress();
                    setCurrentAddress(address);
                    setConnected(true);
                    const network = await provider.getNetwork();
                    setChainId(network.chainId);

                    if (perkNetwork === 'coston') await networkModule.UseCOSTONnetwork();
                    else if (perkNetwork === 'songbird') await networkModule.UseSGBnetwork();
                    else if (perkNetwork === 'flare') await networkModule.UseFLRnetwork();
                    else {
                        setLoading(false);
                        setState(false);
                        return;
                    }

                    await fetchData(provider);
                } catch (error) {
                    console.error('wallet connection error:', error);
                    setLoading(false);
                    setState(false);
                }

                window.ethereum.on('chainChanged', () => window.location.reload());
                window.ethereum.on('accountsChanged', () => window.location.reload());
            };

            init();
        }
    }, [pathname, isPreview, cardHelp]);

    const fetchData = async (provider) => {
        try {
            const contractAddress = CONTRACT_ADDRESSES[perkNetwork];
            if (!contractAddress) {
                throw new Error(`${perkNetwork} isn't one of the networks available'`);
            }
            console.log(`Using contract address: ${contractAddress} for network: ${perkNetwork}`);

            const contract = new ethers.Contract(contractAddress, ABI, provider);
            const parsedPerkNumber = parseInt(perkNumber, 10);
            if (isNaN(parsedPerkNumber)) {
                throw new Error(`Invalid perkNumber: ${perkNumber}`);
            }
            console.log(`Fetching PerkCardData for perkNumber: ${parsedPerkNumber}`);

            const perkData = await contract.PerkCardData(parsedPerkNumber);
            console.log('Raw perkData:', perkData);

            if (!perkData || perkData.length !== 11) {
                throw new Error('Invalid perk data returned from contract');
            }

            const titles = perkData[3].split('|');
            const tokens = perkData[4].split('|');
            const links = perkData[5].split('|');

            const updatedCardData = {
                title: titles[1] || '',
                name: titles[0] || '',
                recipient: `address: ${perkData[1]}`,
                description: perkData[6] || '',
                imageLink: links[0] || cardData.imageLink,
                imageSize: links[0] ? 'cover' : 'contain',
                website: links[1] || '',
                contentLink: links[2] || '',
                token1: tokens[0] || 'WSGB',
                token2: tokens[1] || 'PERK',
                token3: tokens[2] || 'WFLR',
                tags: perkData[7] || '',
            };

            console.log('Updated cardData:', updatedCardData);
            setCardData(updatedCardData);
            setState(true);
        } catch (error) {
            console.error('error fetching perk data:', error);
            setState(false);
        } finally {
            setLoading(false);
        }
    };

    const sendTokens = async (token, amountInput) => {
        if (!amountInput || Number(amountInput) <= 0) {
            setShowApproveMessages('please enter a valid amount...');
            setShowApproveButton(true);
            return;
        }

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        if (!provider) return;

        try {
            await provider.send('eth_requestAccounts', []);
            const signer = provider.getSigner();
            const address = await signer.getAddress();
            setCurrentAddress(address);
            setConnected(true);

            await tokenModule.CheckTokenNetwork(token);
            setShowApproveMessages('please approve the transaction...');
            setShowDYKmessage('did you know?\n\nKYC is not an infallible process and it can be manipulated like any other standardized system...');
            setShowApproveButton(true);

            const amount = ethers.utils.parseEther(amountInput);
            let tokenContractAddress;
            let marketContractAddress;

            if (perkNetwork === 'coston') {
                tokenContractAddress = (token === 'PERK' || token === 'PERK[C]') ? contractModule.PERKTokenCoston : null;
                marketContractAddress = contractModule.FactoryMarketCOSTON;
            } else if (perkNetwork === 'songbird') {
                tokenContractAddress = token === 'WSGB' ? contractModule.TokenWSGB : (token === 'PERK' || token === 'PERK[S]') ? contractModule.PERKTokenSGB : null;
                marketContractAddress = contractModule.FactoryMarketSGB;
            } else if (perkNetwork === 'flare') {
                tokenContractAddress = token === 'WFLR' ? contractModule.TokenWFLR : (token === 'PERK' || token === 'PERK[F]') ? contractModule.PERKTokenFLR : null;
                marketContractAddress = contractModule.FactoryMarketFLR;
            }

            if (!tokenContractAddress) {
                throw new Error(`No token contract address defined for ${token} on ${perkNetwork}`);
            }

            const tokenContract = new ethers.Contract(
                tokenContractAddress,
                ['function allowance(address owner, address spender) view returns (uint256)',
                    'function approve(address spender, uint256 amount) returns (bool)',
                    'function balanceOf(address owner) view returns (uint256)'],
                signer
            );
            const allowance = await tokenContract.allowance(address, marketContractAddress);

            if (allowance.lt(amount)) {
                setShowApproveMessages('please approve the contract to use your tokens...');
                setShowDYKmessage("did you know? \n\n'approvals' do not spend money; any time you want to use an ERC20 token (i.e. WSGB) you need to give permission to the dApp");
                const approvalTx = await tokenContract.approve(
                    marketContractAddress,
                    ethers.utils.parseEther('100000000000000000000000000'),
                    { gasLimit: 200000 }
                );
                await approvalTx.wait();
                setShowApproveMessages('... please approve the transaction');
            }

            const balance = await tokenContract.balanceOf(address);
            if (balance.lt(amount)) {
                setShowApproveMessages("you don�t have enough tokens...");
                return;
            }

            const marketContract = new ethers.Contract(
                marketContractAddress,
                ['function sendTokensFee2(address token, address to, uint256 amount)'],
                signer
            );
            const tx = await marketContract.sendTokensFee2(
                tokenContractAddress,
                cardData.recipient.split('address: ')[1],
                amount,
                { gasLimit: 200000 }
            );
            const receipt = await tx.wait();
            setTransactionHash(receipt.transactionHash);
            setTransactionLink(localStorage.getItem('explorerLink') + receipt.transactionHash);
            setreceiptMessage("the transaction was successful...");
            setShowApproveButton(false);
        } catch (error) {
            console.error('error sending tokens:', error);
            setShowApproveMessages(`error: ${error.message || 'transaction failed'}`);
        }
    };

    const CardComponent = () => (
        <div className="card">
            <input type="checkbox" id="card3" className="more" />
            <div className="content">
                <div
                    className="front"
                    style={{
                        backgroundImage: `url(${cardData.imageLink})`,
                        backgroundPosition: 'center',
                        backgroundSize: cardData.imageSize,
                        backgroundRepeat: 'no-repeat',
                    }}
                >
                    <div className="inner">
                        <h2>{cardData.name || 'Unnamed Perk'}</h2>
                        <div className="rating">
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                        </div>
                        <label htmlFor="card3" className="button" aria-hidden="true">details</label>
                    </div>
                </div>
                <div className="back">
                    <div className="inner">
                        <div className="tokens">
                            <div
                                className="token-symbols"
                                onClick={() => {
                                    const inputValue = document.querySelector('input[placeholder="amount to send"]').value;
                                    if (!isPreview && inputValue && Number(inputValue) > 0) sendTokens(cardData.token1, inputValue);
                                }}
                            >
                                <span>{cardData.token1}</span>
                            </div>
                        </div>
                        <div className="tokens">
                            <div
                                className="token-symbols"
                                onClick={() => {
                                    const inputValue = document.querySelector('input[placeholder="amount to send"]').value;
                                    if (!isPreview && inputValue && Number(inputValue) > 0) sendTokens(cardData.token2, inputValue);
                                }}
                            >
                                <span>{cardData.token2}</span>
                            </div>
                        </div>
                        <div className="tokens">
                            <div
                                className="token-symbols"
                                onClick={() => {
                                    const inputValue = document.querySelector('input[placeholder="amount to send"]').value;
                                    if (!isPreview && inputValue && Number(inputValue) > 0) sendTokens(cardData.token3, inputValue);
                                }}
                            >
                                <span>{cardData.token3}</span>
                            </div>
                        </div>
                        <div className="description">
                            <p align="center">{cardData.website || ''}</p>
                            <p align="center">____________________</p>
                            <p align="center">{cardData.recipient || ''}</p>
                            <p align="center">____________________</p>
                            <p>{cardData.description || ''}</p>
                        </div>
                        <div className="title">
                            <p>{cardData.title || ''}</p>
                            <input
                                type="text"
                                placeholder="amount to send"
                            />
                        </div>
                        <label htmlFor="card3" className="button return" aria-hidden="true">front</label>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className={`PerkCard ${state ? 'loaded' : ''}`}>
            <div className='chainName'>{chainNameCard}</div>

            {loading ? (
                <div className="loading">looking for card...</div>
            ) : state ? (
                <>
                    <CardComponent />
                    {showApproveButton && (
                        <div className="back-Mainoverlay fade-in">
                            <div id="approval-objects">
                                <div className="showApproveMessage">{showApproveMessage}</div>
                                <br />
                                <div
                                    id="goBackbutton"
                                    className="payButtons"
                                    onClick={() => setShowApproveButton(false)}
                                >
                                    GO BACK
                                </div>
                                <br /><br /><br />
                                    <div className="DYKmessage">{showDYKmessage}</div>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div className="loading">card not found or failed to load [try to refresh]...</div>
            )}

            {transactionHash && (
                <div className="transaction-success">
                    <br />
                    {receiptMessage && <div id="errorMessage">{receiptMessage}
                        <br />
                        <a href={transactionLink} target="_blank" rel="noreferrer">
                            {transactionHash}</a>
                    </div>}
                </div>
            )}
        </div>
    );
}

export default FactoryCard;