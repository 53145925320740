import './factory.css';
import './factory-card.css';
import FactoryCard from './factory-card.js';
import React, { useEffect, useState } from "react";

function PerkLink() {
    const getDefaultMode = () => {
        const savedMode = localStorage.getItem('mode');
        return savedMode ? savedMode : 'WhiteMode';
    };

    const [mode, setMode] = useState(getDefaultMode());

    useEffect(() => {
        document.body.classList.remove('WhiteMode', 'DarkMode');
        document.body.classList.add(mode);
    }, [mode]);

    const handleToggleDarkMode = () => {
        const newMode = mode === 'WhiteMode' ? 'DarkMode' : 'WhiteMode';
        setMode(newMode);
        localStorage.setItem('mode', newMode);
    };

    const redirectANDclearswitch = () => {
        localStorage.setItem('chainNameCard', '');
        window.location.href = '/';
    };

    return (
        <div className="App">
            <FactoryCard />
            <div id="helpMode" className="btn-on" onClick={redirectANDclearswitch} style={{ cursor: 'pointer' }}>home</div>
            <div id="darkMode" className="btn-on" onClick={handleToggleDarkMode} style={{ cursor: 'pointer' }}>dark</div>
        </div>
    );
}

export default PerkLink;