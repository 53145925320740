


import './factory.css';

import React, { createContext, useContext } from "react";



//________________________________________________________________________________________
//FEE VARIABLES

//const [payCardFeeWSGB, setpayCardFeeWSGB] = useState(ethers.utils.parseUnits("2000"));
//const [payCardFeePERKs, setpayCardFeePERKs] = useState(ethers.utils.parseUnits("2000"));
//const [buyPERKsFeeSGB, setpayFeeSGB] = useState(ethers.utils.parseUnits("1002"));


const FeesContext = createContext({
    payCardFeeWSGB: 2000,
    payCardFeeWFLR: 2000,
    payCardFeePERKCFLR: 2000,
    payCardFeePERKSGB: 2000,
    payCardFeePERKFLR: 2000,
    buyPERKsFeeWSGB: 6,
    buyPERKsFeeWFLR: 6,
});

export const FeesProvider = ({ children }) => {
    const fees = {
        payCardFeeWSGB: 2000,
        payCardFeeWFLR: 2000,
        payCardFeePERKCFLR: 2000,
        payCardFeePERKSGB: 2000,
        payCardFeePERKFLR: 2000,
        buyPERKsFeeWSGB: 6,
        buyPERKsFeeWFLR: 6,
    };

    return <FeesContext.Provider value={fees}>{children}</FeesContext.Provider>;
};

export const useFees = () => {
    const context = useContext(FeesContext);
    if (context === undefined) {
        throw new Error('useFees must be used within a FeesProvider');
    }
    return context;
};










//________________________________________________________________________________________
// gemini generated instructions
export const myVariable = "Hello from myModule!";
export let anotherVariable = 42;  // You can also export let and var (though const is often preferred for immutability)
export function myFunction(name) {
    return `Greetings, ${name}!`;
}
export const myObject = {
    name: "Module Object",
    value: 123
};


// You can also export a default value. Only one default export per module is allowed.
const defaultValue = "This is the default export";
export default defaultValue; // This is how you export it
//________________________________________________________________________________________