import './factory.css';
import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import * as contractModule from "./factoryvariables-contracts.js";
import * as networkModule from "./factoryvariables-networks.js";
import * as tokenModule from "./factoryvariables-tokens.js";
import * as feesModule from "./factoryvariables-fees.js";

class PerkTokens extends React.Component {
    constructor(props) {
        super(props);
        this.state = { visible: false };
    }

    render() {
        return (
            <div className="App">
                <MyFunctions />
            </div>
        );
    }
}

function MyFunctions() {
    const [fadingOutApprove, setFadingOutApprove] = useState(false);
    const [error, setError] = useState("");

    const { payCardFeeWSGB, payCardFeePERKs, buyPERKsFeeWSGB, buyPERKsFeeWFLR } = feesModule.useFees();

    const [connected, setConnected] = useState(false);
    const [currentAddress, setCurrentAddress] = useState('');
    const [chainNameCard, setChainNameCard] = useState(localStorage.getItem('chainNameCard'));
    const [showMenu, setShowMenu] = useState(true);
    const [showApproveButton, setShowApproveButton] = useState(false);
    const [showClaimButtons, setshowClaimButtons] = useState(false);
    const [showApproveMessages, setShowApproveMessages] = useState('');
    const [showDYKmessage, setShowDYKmessage] = useState('');
    const [transactionHash, setTransactionHash] = useState(null);
    const [result, setResult] = useState('');
    const [transactionLink, setTransactionLink] = useState('');
    const [resultMessage, setResultMessage] = useState('');
    const [soonFLR, setSoonFLR] = useState('');
    const [showDarkMode, setShowDarkMode] = useState(true);

    const [provider, setProvider] = useState(null);
    const [signer, setSigner] = useState(null);
    const [factoryMarketContract, setFactoryMarketContract] = useState(null);

    const getDefaultMode = () => localStorage.getItem("mode") || "WhiteMode";
    const [mode, setMode] = useState(getDefaultMode());
    useEffect(() => {
        console.log("Applying mode:", mode);
        document.body.classList.remove("WhiteMode", "DarkMode");
        document.body.classList.add(mode);
        localStorage.setItem("mode", mode);
    }, [mode]);
    const handleToggleDarkMode = () => {
        console.log("Dark mode clicked");
        const newMode = mode === "WhiteMode" ? "DarkMode" : "WhiteMode";
        setMode(newMode);
    };


    useEffect(() => {
        localStorage.setItem('chainNameCard', "");
        setError("");
        waitFunction();

        const initProvider = async () => {
            if (!provider) {
                const newProvider = contractModule.createProvider();
                setProvider(newProvider);
                try {
                    await newProvider.send('eth_requestAccounts', []);
                    const newSigner = contractModule.createSigner(newProvider);
                    setSigner(newSigner);
                    const address = await newSigner.getAddress();
                    console.log("Initialized Address:", address);
                    setCurrentAddress(address);
                    setConnected(true);
                } catch (err) {
                    console.log("User denied account access or no wallet detected:", err);
                    setError("Please connect your wallet");
                }
            }
        };
        initProvider();

        return () => { };
    }, []);

    useEffect(() => {
        const initializeContract = async () => {
            if (provider && signer) {
                let factoryMarketContractInstance;
                const chainId = (await provider.getNetwork()).chainId;
                console.log("Chain ID:", chainId);
                if (chainId === 19) {
                    factoryMarketContractInstance = contractModule.createSGBFactoryMarket_SignY(signer);
                } else if (chainId === 14) {
                    factoryMarketContractInstance = contractModule.createFLRFactoryMarket_SignY(signer);
                } else if (chainId === 16) {
                    factoryMarketContractInstance = contractModule.createCOSTONFactoryMarket_SignY(signer);
                } else {
                    setError("Unsupported network");
                    return;
                }
                console.log("Factory Market Address:", factoryMarketContractInstance.address);
                if (!ethers.utils.isAddress(factoryMarketContractInstance.address)) {
                    setError("error: invalid factory market address");
                    return;
                }
                console.log("Signer Address:", await signer.getAddress());

                setFactoryMarketContract(factoryMarketContractInstance);
                console.log("Factory Market Contract Initialized:", factoryMarketContractInstance.address);
            }
        };
        initializeContract();
    }, [provider, signer]);

    const delay = ms => new Promise(res => setTimeout(res, ms));
    const waitFunction = async () => {
        await delay(800);
        setSoonFLR("100% of the dApp factory fees are distributed to PERKs holders!");
    };

    const chainChanged = () => {
        window.location.reload();
    };
    window.ethereum.on('chainChanged', chainChanged);
    window.ethereum.on('accountsChanged', chainChanged);

    const switchandBuySGB = async () => {
        setResultMessage("");
        setTransactionHash("");
        setTransactionLink("");
        setError("");
        setSoonFLR('');
        if (soonFLR === "" || soonFLR === "100% of the dApp factory fees are distributed to PERKs holders!" || soonFLR === "sorry, we're still testing on SongBird [as good developers should!]") {
        networkModule.UseSGBnetwork();
            try {
                if (!provider || !signer || !factoryMarketContract) {
                    const newProvider = contractModule.createProvider();
                    await newProvider.send('eth_requestAccounts', []);
                    const newSigner = contractModule.createSigner(newProvider);
                    setProvider(newProvider);
                    setSigner(newSigner);
                    const address = await newSigner.getAddress();
                    setConnected(true);
                    setCurrentAddress(address);

                    const chainId = (await newProvider.getNetwork()).chainId;
                    if (chainId !== 19) {
                        throw new Error("you are not on the Songbird network...");
                    }
                } else {
                    await provider.send('eth_requestAccounts', []);
                }

                // Use TokenWSGB from contractModule as the payment token
                const paymentTokenAddress = contractModule.TokenWSGB;
                const paymentAmount = ethers.utils.parseUnits(buyPERKsFeeWSGB.toString(), 18);

                // Create payment token contract instance
                const paymentTokenContract = contractModule.createWSGBToken(signer);

                // Check balance
                const balance = await paymentTokenContract.balanceOf(currentAddress);
                if (balance.lt(paymentAmount)) {
                    setShowApproveMessages('you do not have enough WSGB to make this purchase...');
                    setShowDYKmessage("did you know? \n\n you can earn a portion of all transaction fees by holding PERK tokens...");
                    setShowApproveButton(true);
                    return;
                }

                // Check and set allowance if needed
                const allowance = await paymentTokenContract.allowance(currentAddress, factoryMarketContract.address);
                if (allowance.lt(paymentAmount)) {
                    setShowApproveMessages('please approve the contract to use your tokens...');
                    setShowDYKmessage("did you know? \n\n'approvals' do not spend money; any time you want to use an ERC20 token (i.e. WSGB) you need to give permission to the dApp");
                    setShowApproveButton(true);

                    const approveTx = await paymentTokenContract.approve(factoryMarketContract.address, paymentAmount);
                    await approveTx.wait();
                }

                // Execute the buy transaction
                setShowApproveMessages('... please approve the transaction');
                setShowDYKmessage("did you know? \n\n you can earn a portion of all transaction fees by holding PERK tokens...");
                setShowApproveButton(true);

                const buyTx = await factoryMarketContract.buyTokensWSGB(
                    paymentTokenAddress,
                    paymentAmount,
                    { gasLimit: 200000 }
                );

                const receipt = await buyTx.wait();
                if (receipt.transactionHash) {
                    setTransactionHash(receipt.transactionHash);
                    localStorage.setItem('receiptHash', receipt.transactionHash);
                    setTransactionLink('https://songbird-explorer.flare.network/tx/' + receipt.transactionHash);
                    setShowApproveButton(false);
                    setError("Transaction successful!");
                } else {
                    throw new Error("Transaction failed");
                }
            } catch (err) {
                setShowApproveMessages(`error: ${err.message}`);
                setShowApproveButton(true);
                setError(`error: ${err.message}`);
            }
        }
    };

    const switchandBuyFLR = async () => {
        setResultMessage("");
        setTransactionHash("");
        setTransactionLink("");
        setError("");
        setSoonFLR('');
        setSoonFLR("sorry, we're still testing on SongBird [as good developers should!]");
        //if (soonFLR === "" || soonFLR === "100% of the dApp factory fees are distributed to PERKs holders!") {
        if (soonFLR === "something else") {
        networkModule.UseFLRnetwork();
            try {
                if (!provider || !signer || !factoryMarketContract) {
                    const newProvider = contractModule.createProvider();
                    await newProvider.send('eth_requestAccounts', []);
                    const newSigner = contractModule.createSigner(newProvider);
                    setProvider(newProvider);
                    setSigner(newSigner);
                    const address = await newSigner.getAddress();
                    setConnected(true);
                    setCurrentAddress(address);

                    const chainId = (await newProvider.getNetwork()).chainId;
                    if (chainId !== 14) {
                        throw new Error("you are not on the Flare network...");
                    }
                } else {
                    await provider.send('eth_requestAccounts', []);
                }

                // Use TokenWSGB from contractModule as the payment token
                const paymentTokenAddress = contractModule.TokenWSGB;
                const paymentAmount = ethers.utils.parseUnits(buyPERKsFeeWSGB.toString(), 18);

                // Create payment token contract instance
                const paymentTokenContract = contractModule.createWSGBToken(signer);

                // Check balance
                const balance = await paymentTokenContract.balanceOf(currentAddress);
                if (balance.lt(paymentAmount)) {
                    setShowApproveMessages('you do not have enough WFLR to make this purchase...');
                    setShowDYKmessage("did you know? \n\n you can earn a portion of all transaction fees by holding PERK tokens!");
                    setShowApproveButton(true);
                    return;
                }

                // Check and set allowance if needed
                const allowance = await paymentTokenContract.allowance(currentAddress, factoryMarketContract.address);
                if (allowance.lt(paymentAmount)) {
                    setShowApproveMessages('please approve the contract to use your tokens...');
                    setShowDYKmessage("did you know? \n\n any time you want to use an ERC20 token (i.e. WFLR) you need to give permission to the dApp");
                    setShowApproveButton(true);

                    const approveTx = await paymentTokenContract.approve(factoryMarketContract.address, paymentAmount);
                    await approveTx.wait();
                }

                // Execute the buy transaction
                setShowApproveMessages('please approve the transaction...');
                setShowDYKmessage("did you know? \n\n you can earn a portion of all transaction fees by holding PERK tokens!");
                setShowApproveButton(true);

                const buyTx = await factoryMarketContract.buyTokensWSGB(
                    paymentTokenAddress,
                    paymentAmount,
                    { gasLimit: 200000 }
                );

                const receipt = await buyTx.wait();
                if (receipt.transactionHash) {
                    setTransactionHash(receipt.transactionHash);
                    localStorage.setItem('receiptHash', receipt.transactionHash);
                    setTransactionLink('https://songbird-explorer.flare.network/tx/' + receipt.transactionHash);
                    setShowApproveButton(false);
                    setError("Transaction successful!");
                } else {
                    throw new Error("Transaction failed");
                }
            } catch (err) {
                setShowApproveMessages(`error: ${err.message}`);
                setShowApproveButton(true);
                setError(`error: ${err.message}`);
            }
        }
    };

    const claimPerksButtons = async () => {
        setResultMessage("");
        setTransactionHash("");
        setTransactionLink("");
        setError("");
        setSoonFLR('');
        setshowClaimButtons(true);
    };

    const claimPerksCOSTON = async () => {
        networkModule.UseCOSTONnetwork();
        setSoonFLR('');
        try {
            let userAddress = currentAddress;
            if (!provider || !signer || !factoryMarketContract) {
                const newProvider = contractModule.createProvider();
                await newProvider.send('eth_requestAccounts', []);
                const newSigner = contractModule.createSigner(newProvider);
                setProvider(newProvider);
                setSigner(newSigner);
                userAddress = await newSigner.getAddress();
                setConnected(true);
                setCurrentAddress(userAddress);

                setFactoryMarketContract(contractModule.createCOSTONFactoryMarket_SignY(newSigner));

            } else {
                await provider.send('eth_requestAccounts', []);
                if (!userAddress) {
                    userAddress = await signer.getAddress();
                }
            }

            console.log("User Address:", userAddress);
            if (!ethers.utils.isAddress(userAddress)) {
                throw new Error("user address is invalid or not set...");
            }

            console.log("Contract Address:", factoryMarketContract.address);
            setShowApproveMessages('please standby...');
            setShowDYKmessage("did you know? \n\n you are claiming your portion of fees from all of the functions on the dApp factory [multiple tokens at once!]");
            setShowApproveButton(true);

            console.log("Fetching supported ERC-20 tokens...");
            let supportedTokens = [];
            try {
                let i = 0;
                while (true) {
                    try {
                        const token = await factoryMarketContract["supportedERC20Tokens(uint256)"](i);
                        console.log(`supportedERC20Tokens(${i}): ${token}`);
                        if (token === ethers.constants.AddressZero || !ethers.utils.isAddress(token)) {
                            break;
                        }
                        supportedTokens.push(token);
                        i++;
                    } catch (indexError) {
                        console.log(`supportedERC20Tokens(${i}) failed, assuming end of list:`, indexError.message);
                        break;
                    }
                }
                console.log("supportedERC20Tokens(uint256) result:", supportedTokens);
                if (supportedTokens.length === 0) {
                    throw new Error("no tokens found with supportedERC20Tokens(uint256), trying fallback...");
                }
            } catch (indexError) {
                console.warn("supportedERC20Tokens(uint256) completely failed:", indexError.message);
                try {
                    const tokenArray = await factoryMarketContract["isSupportedERC20Tokens()"]();
                    console.log("isSupportedERC20Tokens() result:", tokenArray);
                    if (Array.isArray(tokenArray)) {
                        supportedTokens = tokenArray;
                    } else {
                        throw new Error("isSupportedERC20Tokens() did not return an array...");
                    }
                } catch (arrayError) {
                    console.warn("isSupportedERC20Tokens() failed:", arrayError.message);
                    supportedTokens = [];
                    console.log("no supported tokens found or functions not implemented.");
                }
            }

            console.log("Final Supported Tokens:", supportedTokens);

            for (let i = 0; i < supportedTokens.length; i++) {
                const tokenAddress = supportedTokens[i];
                if (!ethers.utils.isAddress(tokenAddress)) {
                    console.warn(`Skipping invalid token address at index ${i}: ${tokenAddress}`);
                    continue;
                }
                console.log(`Checking pending distributions for ${tokenAddress}...`);
                const pendingAmount = await factoryMarketContract.pendingDistributions(userAddress, tokenAddress);
                console.log(`Pending Amount for ${tokenAddress}:`, pendingAmount.toString());
                if (pendingAmount.gt(0)) {
                    const distributeTx = await factoryMarketContract.distributeUnhandledERC20(tokenAddress, { gasLimit: 3000000 });
                    const distributeReceipt = await distributeTx.wait();
                    console.log(`Distributed unhandled tokens for ${tokenAddress}: ${distributeReceipt.transactionHash}`);
                }
            }

            console.log("Checking native balance...");
            const nativeBalance = await factoryMarketContract.balanceOfUserNative(userAddress);
            console.log("Native Balance:", nativeBalance.toString());
            let nativeTxHash = '';
            if (nativeBalance.gt(0)) {
                const nativeTx = await factoryMarketContract.claimAllNativeTokens({ gasLimit: 200000 });
                const nativeReceipt = await nativeTx.wait();
                nativeTxHash = nativeReceipt.transactionHash;
                console.log(`Claimed native tokens: ${nativeTxHash}`);
            }

            let erc20TxHashes = [];
            for (let i = 0; i < supportedTokens.length; i++) {
                const tokenAddress = supportedTokens[i];
                if (!ethers.utils.isAddress(tokenAddress)) {
                    console.warn(`Skipping invalid token address at index ${i}: ${tokenAddress}`);
                    continue;
                }
                console.log(`Checking ERC-20 balance for ${tokenAddress}...`);
                const erc20Balance = await factoryMarketContract.balanceOfUserERC20(userAddress, tokenAddress);
                console.log(`ERC-20 Balance for ${tokenAddress}:`, erc20Balance.toString());
                if (erc20Balance.gt(0)) {
                    const erc20Tx = await factoryMarketContract.claimAllERC20Tokens(tokenAddress, { gasLimit: 200000 });
                    const erc20Receipt = await erc20Tx.wait();
                    erc20TxHashes.push(erc20Receipt.transactionHash);
                    console.log(`Claimed ERC-20 tokens for ${tokenAddress}: ${erc20Receipt.transactionHash}`);
                }
            }

            setshowClaimButtons(false);
            setShowApproveButton(false);
            setResultMessage("receipt[s]:" + (nativeTxHash || erc20TxHashes.length > 0 ? "" : ""));
            setError(`the transaction succeeded...`);
            setTransactionHash(
                (nativeTxHash ? `${nativeTxHash}\n` : '') +
                erc20TxHashes.map((hash, index) => `${index + 1}: ${hash}`).join('\n') ||
                (nativeTxHash || erc20TxHashes.length > 0 ? '' : 'but there were no tokens to claim...')
            );
            setTransactionLink(localStorage.getItem('explorerLink') + (nativeTxHash || erc20TxHashes[0] || ''));
        } catch (err) {
            console.error("Claim failed:", err);
            setShowApproveMessages(`error: ${err.message}`);
            setShowApproveButton(true);
            setError(`error: ${err.message}`);
        }
    };

    const claimPerksSGB = async () => {
        networkModule.UseSGBnetwork();
        setSoonFLR('');
        try {
            let userAddress = currentAddress;
            if (!provider || !signer || !factoryMarketContract) {
                const newProvider = contractModule.createProvider();
                await newProvider.send('eth_requestAccounts', []);
                const newSigner = contractModule.createSigner(newProvider);
                setProvider(newProvider);
                setSigner(newSigner);
                userAddress = await newSigner.getAddress();
                setConnected(true);
                setCurrentAddress(userAddress);

                setFactoryMarketContract(contractModule.createSGBFactoryMarket_SignY(newSigner));

            } else {
                await provider.send('eth_requestAccounts', []);
                if (!userAddress) {
                    userAddress = await signer.getAddress();
                }
            }

            console.log("User Address:", userAddress);
            if (!ethers.utils.isAddress(userAddress)) {
                throw new Error("user address is invalid or not set...");
            }

            console.log("Contract Address:", factoryMarketContract.address);
            setShowApproveMessages('please standby...');
            setShowDYKmessage("did you know? \n\n you are claiming your portion of fees from all of the functions on the dApp factory [multiple tokens at once!]...");
            setShowApproveButton(true);

            console.log("Fetching supported ERC-20 tokens...");
            let supportedTokens = [];
            try {
                let i = 0;
                while (true) {
                    try {
                        const token = await factoryMarketContract["supportedERC20Tokens(uint256)"](i);
                        console.log(`supportedERC20Tokens(${i}): ${token}`);
                        if (token === ethers.constants.AddressZero || !ethers.utils.isAddress(token)) {
                            break;
                        }
                        supportedTokens.push(token);
                        i++;
                    } catch (indexError) {
                        console.log(`supportedERC20Tokens(${i}) failed, assuming end of list:`, indexError.message);
                        break;
                    }
                }
                console.log("supportedERC20Tokens(uint256) result:", supportedTokens);
                if (supportedTokens.length === 0) {
                    throw new Error("no tokens found with supportedERC20Tokens(uint256), trying fallback...");
                }
            } catch (indexError) {
                console.warn("supportedERC20Tokens(uint256) completely failed:", indexError.message);
                try {
                    const tokenArray = await factoryMarketContract["isSupportedERC20Tokens()"]();
                    console.log("isSupportedERC20Tokens() result:", tokenArray);
                    if (Array.isArray(tokenArray)) {
                        supportedTokens = tokenArray;
                    } else {
                        throw new Error("isSupportedERC20Tokens() did not return an array...");
                    }
                } catch (arrayError) {
                    console.warn("isSupportedERC20Tokens() failed:", arrayError.message);
                    supportedTokens = [];
                    console.log("no supported tokens found or functions not implemented.");
                }
            }

            console.log("Final Supported Tokens:", supportedTokens);

            for (let i = 0; i < supportedTokens.length; i++) {
                const tokenAddress = supportedTokens[i];
                if (!ethers.utils.isAddress(tokenAddress)) {
                    console.warn(`Skipping invalid token address at index ${i}: ${tokenAddress}`);
                    continue;
                }
                console.log(`Checking pending distributions for ${tokenAddress}...`);
                const pendingAmount = await factoryMarketContract.pendingDistributions(userAddress, tokenAddress);
                console.log(`Pending Amount for ${tokenAddress}:`, pendingAmount.toString());
                if (pendingAmount.gt(0)) {
                    const distributeTx = await factoryMarketContract.distributeUnhandledERC20(tokenAddress, { gasLimit: 3000000 });
                    const distributeReceipt = await distributeTx.wait();
                    console.log(`Distributed unhandled tokens for ${tokenAddress}: ${distributeReceipt.transactionHash}`);
                }
            }

            console.log("Checking native balance...");
            const nativeBalance = await factoryMarketContract.balanceOfUserNative(userAddress);
            console.log("Native Balance:", nativeBalance.toString());
            let nativeTxHash = '';
            if (nativeBalance.gt(0)) {
                const nativeTx = await factoryMarketContract.claimAllNativeTokens({ gasLimit: 200000 });
                const nativeReceipt = await nativeTx.wait();
                nativeTxHash = nativeReceipt.transactionHash;
                console.log(`Claimed native tokens: ${nativeTxHash}`);
            }

            let erc20TxHashes = [];
            for (let i = 0; i < supportedTokens.length; i++) {
                const tokenAddress = supportedTokens[i];
                if (!ethers.utils.isAddress(tokenAddress)) {
                    console.warn(`Skipping invalid token address at index ${i}: ${tokenAddress}`);
                    continue;
                }
                console.log(`Checking ERC-20 balance for ${tokenAddress}...`);
                const erc20Balance = await factoryMarketContract.balanceOfUserERC20(userAddress, tokenAddress);
                console.log(`ERC-20 Balance for ${tokenAddress}:`, erc20Balance.toString());
                if (erc20Balance.gt(0)) {
                    const erc20Tx = await factoryMarketContract.claimAllERC20Tokens(tokenAddress, { gasLimit: 200000 });
                    const erc20Receipt = await erc20Tx.wait();
                    erc20TxHashes.push(erc20Receipt.transactionHash);
                    console.log(`Claimed ERC-20 tokens for ${tokenAddress}: ${erc20Receipt.transactionHash}`);
                }
            }

            setshowClaimButtons(false);
            setShowApproveButton(false);
            setResultMessage("the transaction succeeded..." + (nativeTxHash || erc20TxHashes.length > 0 ? "receipts:" : ""));
            setTransactionHash(
                (nativeTxHash ? `${nativeTxHash}\n` : '') +
                erc20TxHashes.map((hash, index) => `${index + 1}: ${hash}`).join('\n') ||
                (nativeTxHash || erc20TxHashes.length > 0 ? '' : 'but there were no tokens to claim...')
            );
            setTransactionLink(localStorage.getItem('explorerLink') + (nativeTxHash || erc20TxHashes[0] || ''));
        } catch (err) {
            console.error("Claim failed:", err);
            setShowApproveMessages(`error: ${err.message}`);
            setShowApproveButton(true);
            setError(`error: ${err.message}`);
        }
    };

    const claimPerksFLR = async () => {
        networkModule.UseFLRnetwork();
        setSoonFLR('');
        try {
            let userAddress = currentAddress;
            if (!provider || !signer || !factoryMarketContract) {
                const newProvider = contractModule.createProvider();
                await newProvider.send('eth_requestAccounts', []);
                const newSigner = contractModule.createSigner(newProvider);
                setProvider(newProvider);
                setSigner(newSigner);
                userAddress = await newSigner.getAddress();
                setConnected(true);
                setCurrentAddress(userAddress);

                setFactoryMarketContract(contractModule.createFLRFactoryMarket_SignY(newSigner));

            } else {
                await provider.send('eth_requestAccounts', []);
                if (!userAddress) {
                    userAddress = await signer.getAddress();
                }
            }

            console.log("User Address:", userAddress);
            if (!ethers.utils.isAddress(userAddress)) {
                throw new Error("user address is invalid or not set...");
            }

            console.log("Contract Address:", factoryMarketContract.address);
            setShowApproveMessages('please standby...');
            setShowDYKmessage("did you know? \n\n you are claiming your portion of fees from all of the functions on the dApp factory [multiple tokens at once!]");
            setShowApproveButton(true);

            console.log("Fetching supported ERC-20 tokens...");
            let supportedTokens = [];
            try {
                let i = 0;
                while (true) {
                    try {
                        const token = await factoryMarketContract["supportedERC20Tokens(uint256)"](i);
                        console.log(`supportedERC20Tokens(${i}): ${token}`);
                        if (token === ethers.constants.AddressZero || !ethers.utils.isAddress(token)) {
                            break;
                        }
                        supportedTokens.push(token);
                        i++;
                    } catch (indexError) {
                        console.log(`supportedERC20Tokens(${i}) failed, assuming end of list:`, indexError.message);
                        break;
                    }
                }
                console.log("supportedERC20Tokens(uint256) result:", supportedTokens);
                if (supportedTokens.length === 0) {
                    throw new Error("no tokens found with supportedERC20Tokens(uint256), trying fallback...");
                }
            } catch (indexError) {
                console.warn("supportedERC20Tokens(uint256) completely failed:", indexError.message);
                try {
                    const tokenArray = await factoryMarketContract["isSupportedERC20Tokens()"]();
                    console.log("isSupportedERC20Tokens() result:", tokenArray);
                    if (Array.isArray(tokenArray)) {
                        supportedTokens = tokenArray;
                    } else {
                        throw new Error("isSupportedERC20Tokens() did not return an array...");
                    }
                } catch (arrayError) {
                    console.warn("isSupportedERC20Tokens() failed:", arrayError.message);
                    supportedTokens = [];
                    console.log("no supported tokens found or functions not implemented.");
                }
            }

            console.log("Final Supported Tokens:", supportedTokens);

            for (let i = 0; i < supportedTokens.length; i++) {
                const tokenAddress = supportedTokens[i];
                if (!ethers.utils.isAddress(tokenAddress)) {
                    console.warn(`Skipping invalid token address at index ${i}: ${tokenAddress}`);
                    continue;
                }
                console.log(`Checking pending distributions for ${tokenAddress}...`);
                const pendingAmount = await factoryMarketContract.pendingDistributions(userAddress, tokenAddress);
                console.log(`Pending Amount for ${tokenAddress}:`, pendingAmount.toString());
                if (pendingAmount.gt(0)) {
                    const distributeTx = await factoryMarketContract.distributeUnhandledERC20(tokenAddress, { gasLimit: 3000000 });
                    const distributeReceipt = await distributeTx.wait();
                    console.log(`Distributed unhandled tokens for ${tokenAddress}: ${distributeReceipt.transactionHash}`);
                }
            }

            console.log("Checking native balance...");
            const nativeBalance = await factoryMarketContract.balanceOfUserNative(userAddress);
            console.log("Native Balance:", nativeBalance.toString());
            let nativeTxHash = '';
            if (nativeBalance.gt(0)) {
                const nativeTx = await factoryMarketContract.claimAllNativeTokens({ gasLimit: 200000 });
                const nativeReceipt = await nativeTx.wait();
                nativeTxHash = nativeReceipt.transactionHash;
                console.log(`Claimed native tokens: ${nativeTxHash}`);
            }

            let erc20TxHashes = [];
            for (let i = 0; i < supportedTokens.length; i++) {
                const tokenAddress = supportedTokens[i];
                if (!ethers.utils.isAddress(tokenAddress)) {
                    console.warn(`Skipping invalid token address at index ${i}: ${tokenAddress}`);
                    continue;
                }
                console.log(`Checking ERC-20 balance for ${tokenAddress}...`);
                const erc20Balance = await factoryMarketContract.balanceOfUserERC20(userAddress, tokenAddress);
                console.log(`ERC-20 Balance for ${tokenAddress}:`, erc20Balance.toString());
                if (erc20Balance.gt(0)) {
                    const erc20Tx = await factoryMarketContract.claimAllERC20Tokens(tokenAddress, { gasLimit: 200000 });
                    const erc20Receipt = await erc20Tx.wait();
                    erc20TxHashes.push(erc20Receipt.transactionHash);
                    console.log(`Claimed ERC-20 tokens for ${tokenAddress}: ${erc20Receipt.transactionHash}`);
                }
            }

            setshowClaimButtons(false);
            setShowApproveButton(false);
            setResultMessage("the transaction succeeded..." + (nativeTxHash || erc20TxHashes.length > 0 ? "receipts:" : ""));
            setTransactionHash(
                (nativeTxHash ? `${nativeTxHash}\n` : '') +
                erc20TxHashes.map((hash, index) => `${index + 1}: ${hash}`).join('\n') ||
                (nativeTxHash || erc20TxHashes.length > 0 ? '' : 'but there were no tokens to claim...')
            );
            setTransactionLink(localStorage.getItem('explorerLink') + (nativeTxHash || erc20TxHashes[0] || ''));
        } catch (err) {
            console.error("Claim failed:", err);
            setShowApproveMessages(`error: ${err.message}`);
            setShowApproveButton(true);
            setError(`error: ${err.message}`);
        }
    };
    return (
        <div className="App">
            <div className='chainName'>{chainNameCard}</div>

            <div id="helpMode" className="btn-on" onClick={() => window.open('/', '_self')}>home</div>

            {showDarkMode && (
                <div id="darkMode"
                    className="btn-on"
                    onClick={handleToggleDarkMode}
                    style={{ cursor: "pointer", pointerEvents: "auto", zIndex: 1000 }}
                >
                    dark
                </div>
            )}

            {error && <div id="errorMessage">{error}</div>}

            <div className="start-block">
                {showMenu && (
                    <div id="menu">
                        <div id="menu-options">
                            |{" "}
                            <button
                                id="menu-search"
                                className="menu-button"
                                onClick={switchandBuySGB}
                            >
                                buy SGB perks
                            </button>{" "}
                            |{" "}
                            <button
                                id="menu-mixer"
                                className="menu-button"
                                onClick={claimPerksButtons}
                            >
                                claim perks
                            </button>{" "}
                            |{" "}
                            <button
                                id="menu-generator"
                                className="menu-button"
                                onClick={switchandBuyFLR}
                            >
                                buy FLR perks
                            </button>{" "}
                            |
                        </div>
                    </div>
                )}
                <br /><br />
                <div className="font-title" style={{ whiteSpace: "pre" }}>
                    dApp <div id="flare-f">f</div>actory
                </div>
            </div>

            <div id="tokenPageMessages">
                <div className={`slide-animation ${resultMessage ? 'show' : 'hide'}`}>{resultMessage}
                    <a href={result} target="_blank" rel="noreferrer">{result}</a>
                    <br /><br />
                    <a href={transactionLink} target="_blank" rel="noreferrer">{transactionHash}</a>
                    <br />
                </div>
                <div className={`slide-animation ${soonFLR ? 'show' : 'hide'}`}>
                    {soonFLR}
                </div>
                <div className={`slide-animation ${showClaimButtons ? 'show' : 'hide'}`}>
                    <button className="claim-action-button" onClick={claimPerksCOSTON}>
                        COSTON
                    </button>
                    <br />
                    <button className="claim-action-button" onClick={claimPerksSGB}>
                        SGB
                    </button>
                    <br />
                    <button className="claim-action-button" onClick={claimPerksFLR}>
                        FLR
                    </button>
                </div>
            </div>

            {showApproveButton && (
                <div className={`back-Mainoverlay ${showApproveButton ? 'fade-in' : 'fade-out'}`}>
                    <div id="approval-objects">
                        <div className="approveMessage">{showApproveMessages}</div>
                        <br />
                        <div
                            id="goBackbutton"
                            className="payButtons"
                            onClick={() => {
                                console.log("Go back clicked");
                                setFadingOutApprove(true);
                                setTimeout(() => {
                                    setShowApproveButton(false);
                                    setFadingOutApprove(false);
                                }, 500);
                            }}
                        >
                            GO BACK
                        </div>
                        <br /><br /><br />
                        <div className="DYKmessage">{showDYKmessage}</div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PerkTokens;